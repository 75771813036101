import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, FormControl,
    Grid, InputLabel, MenuItem, Select,
    TextField
} from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { IFormProps } from "../../../lib/form";
import { IOrder, Order } from "../../../models/order";
import { RootStoreContext } from "../../../stores/root.store";
import FormSectionTitle from "../../Form/FormSectionTitle.component";
import FormDialogTitle from "../../FormDialogTitle.component";
import OrderExtrusionForm from "./OrderExtrusionForm";
import OrderGeneralInfoForm from "./OrderGeneralInfoForm";
import OrderPrintingForm from "./OrderPrintingForm";
import OrderSealingForm from "./OrderSealingForm";
import OrderClientInfoForm from "./OrderClientInfoForm";
import { useToast } from "../../../lib/customHooks";

const OrderForm = (props: IFormProps) => {
    const { apiStore } = useContext(RootStoreContext);
    const { success, error } = useToast();

    const originalOrder = useRef<IOrder | null>(null);
    const initialMount = useRef(true);

    const { control, reset, handleSubmit, setValue, getValues } = useForm<IOrder>({
        defaultValues: new Order()
    })

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const orderDateWatcher = useWatch({ control: control, name: 'order_date' });

    const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const refWidth = Number(event.target.value);
        const extrusionWidth = (refWidth * 2) + 3;

        setValue("extrusion_width", extrusionWidth);
        setValue("extrusion_width_min", extrusionWidth);
        setValue("extrusion_width_max", extrusionWidth + 0.5);
    }

    const onSubmit: SubmitHandler<IOrder> = async (values) => {
        try {
            setSubmitting(true);
            if (originalOrder.current !== null) {
                // References
                const currentReferencesIds = values.order_references
                    .map(r => r.id)
                    .filter(id => id > 0);
                originalOrder.current.order_references
                    .forEach(r => {
                        if (!currentReferencesIds.includes(r.id)) {
                            values.order_references.push({ ...r, _destroy: true });
                        }
                    })

                // Mix entries
                const currentMixEntriesIds = values.order_mix_entries
                    .map(e => e.id)
                    .filter(id => id > 0);
                originalOrder.current.order_mix_entries
                    .forEach(e => {
                        if (!currentMixEntriesIds.includes(e.id)) {
                            values.order_mix_entries.push({ ...e, _destroy: true });
                        }
                    });

                // Secondary mix entries
                const currentSecMixEntriesIds = values.order_secondary_mix_entries
                    .map(e => e.id)
                    .filter(id => id > 0);
                originalOrder.current?.order_secondary_mix_entries
                    .forEach(e => {
                        if (!currentSecMixEntriesIds.includes(e.id)) {
                            values.order_secondary_mix_entries.push({ ...e, _destroy: true });
                        }
                    })

                // Inks
                const currentSideAInksIds = values.order_print_ink_side_as
                    .map(i => i.id)
                    .filter(id => id > 0);
                originalOrder.current.order_print_ink_side_as
                    .forEach(i => {
                        if (!currentSideAInksIds.includes(i.id)) {
                            values.order_print_ink_side_as.push({ ...i, _destroy: true });
                        }
                    });

                const currentSideBInksIds = values.order_print_ink_side_bs
                    .map(i => i.id)
                    .filter(id => id > 0);

                originalOrder.current.order_print_ink_side_bs
                    .forEach(i => {
                        if (!currentSideBInksIds.includes(i.id)) {
                            values.order_print_ink_side_bs.push({ ...i, _destroy: true });
                        }
                    });
            }
            // Items should be null
            values.order_mix_entries.forEach(e => {
                e.item = null;
            });

            values.order_secondary_mix_entries.forEach(e => {
                e.item = null;
            });

            await apiStore.submitOrder(values, props.id);
            props.onSubmit();
        } catch (e: any) {
            error(e.message);
        } finally {
            setSubmitting(false);
        }
    }

    const getLatestCode = useCallback(async (date: Date) => {
        const data = await apiStore.getWithLatestCode(date);
        setValue("order_number", data.order_number);
        setValue("order_code", data.order_code);
    }, []);

    useEffect(() => {
        if (initialMount.current) {
            initialMount.current = false;
            return;
        }

        // Prevent fetching a new code if we use the same month
        if (props.id > 0 && originalOrder.current) {
            const originalDate = dayjs(originalOrder.current.order_date);
            const currDate = dayjs(orderDateWatcher);

            if (originalDate.month() === currDate.month()) {
                setValue("order_number", originalOrder.current.order_number);
                setValue("order_code", originalOrder.current.order_code);
                return;
            }
        }

        (async () => {
            await getLatestCode(orderDateWatcher);
        })();
    }, [orderDateWatcher]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (props.id > 0) {
                    const data = await apiStore.getOrder(props.id);
                    // Fix for undefined values
                    for (let i = 0; i < data.order_mix_entries?.length; i++) {
                        data.order_mix_entries[i].item = null;
                    }
                    for (let i = 0; i < data.order_secondary_mix_entries?.length; i++) {
                        data.order_secondary_mix_entries[i].item = null;
                    }
                    originalOrder.current = data;
                    reset(data);
                } else {
                    await getLatestCode(new Date());
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.id]);

    return (
        <>
            <DialogTitle>
                <FormDialogTitle
                    edit={props.id > 0}
                    loading={loading}
                    onClose={props.onClose}
                    title="Order"
                />
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} alignItems={"center"}>
                    <Grid container item spacing={3} alignItems={"flex-start"}>
                        <Grid container item xs={12} lg={6} spacing={3}>
                            <OrderClientInfoForm control={control} />
                        </Grid>
                        <Grid container item xs={12} lg={6} spacing={3}>
                            <FormSectionTitle title={"Reference"} />
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={"reference_name"}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={"Name"}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={"reference_paper_color"}
                                    render={({ field }) => (
                                        <FormControl fullWidth>
                                            <InputLabel id={"paper-color-select-label"}>Paper</InputLabel>
                                            <Select
                                                {...field}
                                                label={"Paper"}
                                                labelId={"paper-color-select-label"}
                                                id={"paper-color-select"}
                                            >
                                                <MenuItem value={0}>N/A</MenuItem>
                                                <MenuItem value={1}>White</MenuItem>
                                                <MenuItem value={2}>Kraft</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={"reference_width"}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            onChange={e => {
                                                handleWidthChange(e);
                                                field.onChange(e);
                                            }}
                                            label={"Width (cms)"}
                                            type={"number"}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={"reference_height"}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={"Height (cms)"}
                                            type={"number"}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={"reference_grammage"}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={"Grammage"}
                                            type={"number"}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={"reference_sides"}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={"Sides"}
                                            type={"number"}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    control={control}
                                    name={"reference_bellows"}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={"Bellows"}
                                            type={"number"}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3} alignItems={"center"}>
                        <OrderGeneralInfoForm
                            getValues={getValues}
                            setValue={setValue}
                            control={control}
                        />
                    </Grid>
                    <Grid container item spacing={3} alignItems={"center"}>
                        <OrderExtrusionForm
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Grid>
                    <Grid container item spacing={3} alignItems={"center"}>
                        <OrderPrintingForm
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Grid>
                    <Grid container item spacing={3} alignItems={"center"}>
                        <OrderSealingForm
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                        />
                    </Grid>
                    {/* Order comment */}
                    <Grid item container spacing={2} direction={"column"}>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Controller
                                control={control}
                                name={"order_comment"}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label={"Comment"}
                                        rows={5}
                                        multiline
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color={"secondary"} disabled={submitting}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={submitting}>
                    Submit
                </Button>
            </DialogActions>
        </>
    )
}

export default OrderForm;
