import { IItem } from "./item";
import { IPaginateMetadata } from "./paginate";

export interface IOrderReference {
    id: number;
    reference_number: number;
    _destroy: boolean;
}

export class OrderReference implements IOrderReference {
    id: number = 0;
    reference_number: number = 0;
    _destroy: boolean = false;
}

export interface IOrderPrintInk {
    id: number;
    color_name: string;
    _destroy: boolean;
}

export class OrderPrintInk implements IOrderPrintInk {
    id: number = 0;
    color_name: string = "";
    _destroy: boolean = false;

    constructor(init?: Partial<IOrderPrintInk>) {
        Object.assign(this, init);
    }
}

export interface IOrderMixEntry {
    id: number;
    item_id: string;
    item_name: string;
    item: IItem | null;
    percentage_value: number;
    total_value: number;
    _destroy: boolean;
}

export class OrderMixEntry implements IOrderMixEntry {
    id: number = 0;
    item_id: string = "";
    item_name: string = "";
    percentage_value: number = 0;
    total_value: number = 0;
    item: IItem | null = null;
    _destroy: boolean = false;
}

export interface IOrder {
    id: number;
    added_at?: Date;
    updated_at?: Date;

    order_number: number;
    order_code: string;
    // order_ref_number: number;
    order_date: Date;
    order_comment: string;
    order_references: IOrderReference[];
    references: string;

    client_id: string;
    client_name: string;

    project_name: string;
    location: string;
    city: string;

    reference_name: string;
    reference_width: number;
    reference_height: number;
    reference_grammage: number;
    reference_sides: number;
    reference_bellows: number;
    reference_paper_color: number;

    final_product_weight_per_roll: number;
    final_product_weight_per_core: number;

    general_info_thousand_weight_min: number;
    general_info_thousand_weight_max: number;
    general_info_requested_amount: number;
    general_info_rolls_amount: number;
    general_info_programmed_kilograms: number;
    general_info_waste_percentage: number;
    general_info_waste: number;
    general_info_refile_percentage: number;
    general_info_refile: number;
    general_info_programmed_total: number;

    extrusion_extruder_number: number;
    extrusion_mold: number;
    extrusion_width: number;
    extrusion_width_formula: string;
    extrusion_width_min: number;
    extrusion_width_max: number;
    extrusion_grammage: number;
    extrusion_caliber: number;
    extrusion_caliber_min: number;
    extrusion_caliber_max: number;
    extrusion_pigment: boolean;
    extrusion_treatment: boolean;
    extrusion_treatment_type: number;
    extrusion_bellows: number;

    order_mix_total_percentage: number;
    order_mix_total_value: number;
    order_mix_entries: IOrderMixEntry[];

    order_secondary_mix_total_percentage: number;
    order_secondary_mix_total_value: number;
    order_secondary_mix_entries: IOrderMixEntry[];

    printer_number: number;
    printing_width: number;
    printing_height: number;
    printing_inks_amount: number;
    printing_roller: number;
    printing_mounting: number;
    printing_threaded: number;
    printing_rolls_number: number;
    printing_kg_per_roll: number;
    printing_mt_per_roll: number;

    order_print_ink_side_as: IOrderPrintInk[];
    order_print_ink_side_bs: IOrderPrintInk[];

    sealing_width: number;
    sealing_width_min: number;
    sealing_width_max: number;
    sealing_length: number;
    sealing_length_min: number;
    sealing_length_max: number;
    sealing_side_seal: boolean;
    sealing_seal_bottom: boolean;
    sealing_seal_double_bottom: boolean;
    sealing_cut_bottom: boolean;
    sealing_packages_per_master_case_count: number;
    sealing_amount_per_package: number;
    sealing_amount_per_master_case: number;
    sealing_master_case_count: number;
    sealing_side_bellows: number;
    sealing_bottom_bellows: number;
    sealing_box_type: number;
}

export class Order implements IOrder {
    id: number = 0;
    added_at?: Date;
    updated_at?: Date;

    order_number: number = 0;
    order_code: string = "";
    order_ref_number: number = 0;
    order_comment: string = "";
    order_references: IOrderReference[] = [];
    references: string = "";

    order_date: Date = new Date();
    location: string = "";
    project_name: string = "";

    client_id: string = "";
    client_name: string = "";

    city: string = "Santiago de los Caballeros";

    reference_name: string = "";
    reference_width: number = 0;
    reference_height: number = 0;
    reference_grammage: number = 0;
    reference_sides: number = 0;
    reference_bellows: number = 0;
    reference_paper_color: number = 1;

    final_product_weight_per_roll: number = 40.0;
    final_product_weight_per_core: number = 1.50;

    general_info_thousand_weight_min: number = 0;
    general_info_thousand_weight_max: number = 0;
    general_info_requested_amount: number = 0;
    general_info_rolls_amount: number = 0;
    general_info_programmed_kilograms: number = 0;
    general_info_waste_percentage: number = 0;
    general_info_waste: number = 0;
    general_info_refile_percentage: number = 0;
    general_info_refile: number = 0;
    general_info_programmed_total: number = 0;

    extrusion_extruder_number: number = 0;
    extrusion_mold: number = 0;
    extrusion_width: number = 0;
    extrusion_width_formula: string = "";
    extrusion_width_min: number = 0;
    extrusion_width_max: number = 0;
    extrusion_grammage: number = 0;
    extrusion_caliber: number = 0;
    extrusion_caliber_min: number = 0;
    extrusion_caliber_max: number = 0;
    extrusion_pigment: boolean = false;
    extrusion_treatment: boolean = false;
    extrusion_treatment_type: number = 0;
    extrusion_bellows: number = 0;

    order_mix_total_percentage: number = 0;
    order_mix_total_value: number = 0;
    order_mix_entries: IOrderMixEntry[] = [];

    order_secondary_mix_total_percentage: number = 0;
    order_secondary_mix_total_value: number = 0;
    order_secondary_mix_entries: IOrderMixEntry[] = [];

    printer_number: number = 1;
    printing_width: number = 0;
    printing_height: number = 0;
    printing_inks_amount: number = 0;
    printing_roller: number = 0;
    printing_mounting: number = 0;
    printing_threaded: number = 0;
    printing_rolls_number: number = 0;
    printing_kg_per_roll: number = 0;
    printing_mt_per_roll: number = 0;

    order_print_ink_side_as: IOrderPrintInk[] = [];
    order_print_ink_side_bs: IOrderPrintInk[] = [];

    sealing_width: number = 0;
    sealing_width_min: number = 0;
    sealing_width_max: number = 0;
    sealing_length: number = 0;
    sealing_length_min: number = 0;
    sealing_length_max: number = 0;
    sealing_side_seal: boolean = false;
    sealing_seal_bottom: boolean = false;
    sealing_seal_double_bottom: boolean = false;
    sealing_cut_bottom: boolean = false;
    sealing_packages_per_master_case_count: number = 0;
    sealing_amount_per_package: number = 1000;
    sealing_amount_per_master_case: number = 0;
    sealing_master_case_count: number = 0;
    sealing_bottom_bellows: number = 0;
    sealing_side_bellows: number = 0;
    sealing_box_type: number = 0;
}

export interface IOrdersResponse {
    orders: IOrder[];
    meta: IPaginateMetadata;
}

export class OrdersResponse implements IOrdersResponse {
    orders: IOrder[] = [];
    meta: IPaginateMetadata = {
        total_pages: 1,
        total_records: 0
    }
}

