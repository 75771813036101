import SummarizeIcon from '@mui/icons-material/Summarize';
import { Button, Grid } from "@mui/material";
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { isNaN } from "mathjs";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { EntityType } from "../../lib/util";
import { IOrder } from "../../models/order";
import { RootStoreContext } from "../../stores/root.store";
import FormDialog from "../Form/FormDialog.component";
import IndexTopSection from "../IndexTopSection.component";
import TableActions from "../Table/TableActions.component";
import OrderForm from "./Form/OrderForm";
import OrdersReportsDialog from "./OrdersReportsDialog";
import Util from "../../lib/util";

const OrdersIndex = () => {
    const { apiStore } = useContext(RootStoreContext);

    const [loading, setLoading] = useState(true);
    const [reloading, setReloading] = useState(false);
    const [data, setData] = useState<IOrder[]>([]);
    const [reportsOpen, setReportsOpen] = useState(false);

    const columns = useMemo<MRT_ColumnDef<IOrder>[]>(() => [
        {
            accessorKey: 'order_code',
            header: 'Code',
            enableSorting: false,
        },
        {
            accessorKey: 'client_name',
            header: 'Client',
            enableSorting: false,
        },
        {
            accessorKey: 'project_name',
            header: 'Project',
            enableSorting: false,
        },
        {
            accessorFn: originalRow => originalRow.order_references.map(r => r.reference_number).join(", "),
            id: 'references',
            header: 'References',
            enableSorting: false,
        },
        {
            accessorKey: 'reference_name',
            header: 'Product',
        },
        {
            accessorFn: originalRow => Util.getOrderReferencePaperColor(originalRow.reference_paper_color),
            id: 'reference_paper_color',
            header: 'Color',
            enableSorting: false,
        },
        {
            accessorKey: 'general_info_programmed_kilograms',
            header: 'Programmed (Kgs.)',
            Cell: ({ cell }) => {
                const num = Number(cell.getValue());
                if (!isNaN(num)) {
                    return num.toFixed(2);
                }
                return '';
            },
            enableSorting: false,
        },
        {
            accessorKey: 'general_info_programmed_total',
            header: 'Total (Kgs.)',
            Cell: ({ cell }) => {
                const num = Number(cell.getValue());
                if (!isNaN(num)) {
                    return num.toFixed(2);
                }
                return '';
            },
            enableSorting: false,
        }
    ], []);

    const handleDownloadExcel = useCallback(async (id: number) => {
        try {
            await apiStore.downloadOrderExcel(id);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const handleReload = useCallback(() => {
        setReloading(true);
    }, []);

    const handleOpenReports = useCallback(() => {
        setReportsOpen(true);
    }, []);

    const handleCloseReports = useCallback(() => {
        setReportsOpen(false);
    }, []);

    const loadData = async () => {
        try {
            setLoading(true);
            const response = await apiStore.getOrders();
            setData(response);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setReloading(false);
        }
    }

    useEffect(() => {
        if (loading || reloading) {
            loadData();
        }
    }, [reloading]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowActions: true,
        positionActionsColumn: "last",
        autoResetPageIndex: false,
        enableFullScreenToggle: false,
        renderRowActions: ({ row }) => (
            <TableActions
                entityType={EntityType.ManufacturingOrder}
                id={row.original.id}
                onDelete={handleReload}
                onDuplicate={handleReload}
                download={() => handleDownloadExcel(row.original.id)}
            />
        ),
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 25
            }
        },
        state: {
            isLoading: loading
        },
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100],
        }
    })

    return (
        <>
            <IndexTopSection
                topButtons={(
                    <>
                        <Grid item sx={{ ml: 'auto' }}>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                startIcon={<SummarizeIcon />}
                                onClick={handleOpenReports}
                            >
                                Reports
                            </Button>
                        </Grid>
                    </>
                )}
            />
            {/* Table */}
            <MaterialReactTable table={table} />
            <FormDialog
                content={OrderForm}
                loadData={handleReload}
                fullScreen
            />
            <OrdersReportsDialog
                open={reportsOpen}
                onClose={handleCloseReports}
            />
        </>
    )
}

export default OrdersIndex;
