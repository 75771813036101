import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Control, Controller, useFieldArray, UseFormGetValues, UseFormSetValue, useWatch} from "react-hook-form";
import {IOrder, OrderMixEntry} from "../../../models/order";
import {
    Autocomplete, Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import {IItem} from "../../../models/item";
import {RootStoreContext} from "../../../stores/root.store";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";

export enum OrderMixType {
    Primary, Secondary
}

interface Props {
    control: Control<IOrder>;
    getValues: UseFormGetValues<IOrder>;
    setValue: UseFormSetValue<IOrder>;
    mixType: OrderMixType;
    items: IItem[];
    loadingItems: boolean;
}

const OrderMixEntriesForm = (props: Props) => {
    const title = useMemo(() => {
        if (props.mixType === OrderMixType.Primary) {
            return "Primary Mix";
        }
        return "Secondary Mix";
    }, [props.mixType]);

    const baseName = useMemo(() => {
        if (props.mixType === OrderMixType.Primary) {
            return "order_mix_entries";
        }
        return "order_secondary_mix_entries";
    }, [props.mixType]);

    const totalPercentageName = useMemo(() => {
        if (props.mixType === OrderMixType.Primary) {
            return "order_mix_total_percentage";
        }
        return "order_secondary_mix_total_percentage";
    }, [props.mixType]);

    const totalValueName = useMemo(() => {
        if (props.mixType === OrderMixType.Primary) {
            return "order_mix_total_value";
        }
        return "order_secondary_mix_total_value";
    }, [props.mixType]);

    const {fields, append, remove} = useFieldArray({
        control: props.control,
        name: baseName
    });

    const totalProgrammedWatcher = useWatch({control: props.control, name: 'general_info_programmed_total'});

    const handleNew = useCallback(() => {
        append(new OrderMixEntry());
    }, [append]);

    const handleRemove = (index: number) => {
        remove(index);
        updateMixTotalValues();
    }

    const handleItemChange = (data: IItem | null, index: number) => {
        props.setValue(`${baseName}.${index}.item`, data);
        props.setValue(`${baseName}.${index}.item_id`, data?.ID ?? "");
        props.setValue(`${baseName}.${index}.item_name`, data?.Name ?? "");
    }

    const handlePercentageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const value = Number(event.target.value);
        const values = props.getValues();

        props.setValue(`${baseName}.${index}.percentage_value`, value);
        props.setValue(`${baseName}.${index}.total_value`, Number(values.general_info_programmed_total) * (value / 100.00));

        updateMixTotalValues();
    }

    const updateMixTotalValues = () => {
        const values = props.getValues();
        const entries = props.mixType === OrderMixType.Primary ? values.order_mix_entries : values.order_secondary_mix_entries;
        props.setValue(totalPercentageName, entries.map(x => x.percentage_value)
            .reduce((a, c) => a + c, 0));
        props.setValue(totalValueName, entries.map(x => x.total_value)
            .reduce((a, c) => a + c, 0));
    }

    useEffect(() => {
        if (props.items.length === 0) return;

        const values = props.getValues();
        const entries = props.mixType === OrderMixType.Primary ? values.order_mix_entries : values.order_secondary_mix_entries;
        for (let i = 0; i < entries?.length; i++) {
            const item = props.items.find(x => x.ID === entries[i].item_id) ?? null;
            props.setValue(`${baseName}.${i}.item`, item);
        }
    }, [props.items]);

    useEffect(() => {
        const values = props.getValues();
        const entries = props.mixType === OrderMixType.Primary ? values.order_mix_entries : values.order_secondary_mix_entries;
        const total = Number(values.general_info_programmed_total);

        for (let i = 0; i < entries?.length; i++) {
            props.setValue(`${baseName}.${i}.total_value`, total * (entries[i].percentage_value / 100.00));
        }

        updateMixTotalValues();
    }, [totalProgrammedWatcher]);

    return (
        <>
            <Grid item container spacing={3} alignItems={"center"}>
                <Grid item>
                    <Typography variant={"h6"}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item sx={{display: 'flex', alignItems: 'center'}}>
                    {props.loadingItems && <CircularProgress color={"primary"} size={22}/>}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width={"50%"}>Item</TableCell>
                                <TableCell>%</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell/>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields.map((field, fieldIndex) => (
                                <TableRow key={field.id}>
                                    <TableCell>
                                        <Controller
                                            control={props.control}
                                            name={`${baseName}.${fieldIndex}.item`}
                                            render={({field}) => (
                                                <Autocomplete
                                                    options={props.items}
                                                    value={field.value}
                                                    isOptionEqualToValue={(op, v) => op.ID === v.ID}
                                                    getOptionLabel={op => op.Name}
                                                    onChange={(_, data) => handleItemChange(data, fieldIndex)}
                                                    renderInput={params => (
                                                        <TextField label={"Item"} {...params} />
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <Box component={"li"} {...props} key={option.ID}>
                                                            {option.Name}
                                                        </Box>
                                                    )}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controller
                                            control={props.control}
                                            name={`${baseName}.${fieldIndex}.percentage_value`}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    label={"Percentage"}
                                                    type={"number"}
                                                    fullWidth
                                                    onChange={e => handlePercentageChange(e, fieldIndex)}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography align={"right"}>
                                            <Controller
                                                control={props.control}
                                                name={`${baseName}.${fieldIndex}.total_value`}
                                                render={({field}) => (
                                                    <span>{field.value?.toFixed(2)}</span>
                                                )}
                                            />
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        Kgr
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleRemove(fieldIndex)}
                                            color={"secondary"}
                                        >
                                            <CancelIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell/>
                                <TableCell variant={'head'} align={"right"}>
                                    <Controller
                                        control={props.control}
                                        name={totalPercentageName}
                                        render={({field}) => (
                                            <span>{field.value?.toFixed(1)} %</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={'head'} align={"right"}>
                                    <Controller
                                        control={props.control}
                                        name={totalValueName}
                                        render={({field}) => (
                                            <span>{field.value?.toFixed(2)}</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell>Kgr</TableCell>
                                <TableCell/>
                            </TableRow>
                            <TableRow>
                                <TableCell align={"center"} colSpan={5}>
                                    <Button
                                        variant={"outlined"}
                                        color={"primary"}
                                        onClick={handleNew}
                                        startIcon={<AddIcon/>}
                                    >
                                        Add To {title}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default OrderMixEntriesForm;
