import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@mui/material"
import React, {useContext, useEffect, useState} from "react"
import {Control, Controller, UseFormGetValues, UseFormSetValue, useWatch} from "react-hook-form"
import {IOrder} from "../../../models/order";
import FormSectionTitle from "../../Form/FormSectionTitle.component";
import OrderMixEntriesForm, {OrderMixType} from "./OrderMixEntriesForm";
import {RootStoreContext} from "../../../stores/root.store";
import {IItem} from "../../../models/item";
import {compile} from "mathjs";

interface Props {
    control: Control<IOrder>;
    getValues: UseFormGetValues<IOrder>;
    setValue: UseFormSetValue<IOrder>;
}

const OrderExtrusionForm = (props: Props) => {
    const {apiStore} = useContext(RootStoreContext);

    const [items, setItems] = useState<IItem[]>([]);
    const [loadingItems, setLoadingItems] = useState(true);

    const treatmentWatcher = useWatch({control: props.control, name: "extrusion_treatment"});
    const refGrammageWatcher = useWatch({control: props.control, name: "reference_grammage"});
    const refBellowsWatcher = useWatch({control: props.control, name: "reference_bellows"});

    const setWidthValues = (width: number) => {
        props.setValue("extrusion_width_min", width);
        props.setValue("extrusion_width_max", width + 0.5);
    }

    const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const width = Number(event.target.value);
        setWidthValues(width);
    }

    const handleWidthFormulaChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        try {
            const expression = compile(event.target.value);
            const result = expression.evaluate();
            let width = 0;
            if (!isNaN(result)) {
                width = result;
            }
            props.setValue("extrusion_width", width);
            setWidthValues(width);
        } catch {
            console.log('Extrusion width: Invalid expression');
        }
    }

    useEffect(() => {
        const extrusionGrammage = Number(refGrammageWatcher);
        const extrusionCaliber = extrusionGrammage / 20;

        props.setValue("extrusion_grammage", extrusionGrammage);
        props.setValue("extrusion_caliber", extrusionCaliber);
        props.setValue("extrusion_caliber_min", extrusionCaliber - 0.05);
        props.setValue("extrusion_caliber_max", extrusionCaliber);
    }, [refGrammageWatcher]);

    useEffect(() => {
        if (!treatmentWatcher) {
            props.setValue("extrusion_treatment_type", 0);
        }
    }, [treatmentWatcher]);

    useEffect(() => {
        props.setValue("extrusion_bellows", Number(refBellowsWatcher) / 2.0)
    }, [refBellowsWatcher]);

    useEffect(() => {
        (async () => {
            try {
                setItems(await apiStore.getItems());
            } catch (e) {
                console.log(e);
            } finally {
                setLoadingItems(false);
            }
        })();
    }, []);

    return (
        <>
            <FormSectionTitle title={"Extrusion"}/>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"extrusion_extruder_number"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Extruder No."}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"extrusion_mold"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Mold (mm)"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"extrusion_width_formula"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            onChange={e => {
                                handleWidthFormulaChange(e);
                                field.onChange(e);
                            }}
                            label={"Width Formula"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"extrusion_width"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            onChange={e => {
                                handleWidthChange(e);
                                field.onChange(e);
                            }}
                            label={"Width (cms)"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"extrusion_bellows"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Bellows (cms)"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"extrusion_pigment"}
                    render={({field: {value, ...field}}) => (
                        <FormControlLabel
                            label={"Pigment"}
                            control={
                                <Checkbox {...field} checked={value}/>
                            }
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"extrusion_treatment"}
                    render={({field: {value, ...field}}) => (
                        <FormControlLabel
                            label={"Treatment"}
                            control={
                                <Checkbox {...field} checked={value}/>
                            }
                        />
                    )}
                />
            </Grid>
            {treatmentWatcher && (
                <Grid item xs={12} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={"extrusion_treatment_type"}
                        render={({field}) => (
                            <FormControl fullWidth>
                                <InputLabel id={"treatment-type-select-label"}>Treatment Type</InputLabel>
                                <Select
                                    {...field}
                                    label={"Treatment Type"}
                                    labelId={"treatment-type-select-label"}
                                    id={"treatment-type-select"}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    <MenuItem value={1}>1C</MenuItem>
                                    <MenuItem value={2}>2C</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
            )}
            {/* Computed values */}
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell variant={"head"}>Width</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'extrusion_width'}
                                        render={({field: {value}}) => (
                                            <span>{Number(value).toFixed(2)} cms</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Min. Width</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'extrusion_width_min'}
                                        render={({field: {value}}) => (
                                            <span>{value.toFixed(2)} cms</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Max. Width</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'extrusion_width_max'}
                                        render={({field: {value}}) => (
                                            <span>{value.toFixed(2)} cms</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={"head"}>Caliber</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'extrusion_caliber'}
                                        render={({field: {value}}) => (
                                            <span>{value.toFixed(2)} Mil/pg</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Min. Caliber</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'extrusion_caliber_min'}
                                        render={({field: {value}}) => (
                                            <span>{value.toFixed(2)} Mil/pg</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Max. Caliber</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'extrusion_caliber_max'}
                                        render={({field: {value}}) => (
                                            <span>{value.toFixed(2)} Mil/pg</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {/* Primary Mix fields */}
            <OrderMixEntriesForm
                control={props.control}
                getValues={props.getValues}
                setValue={props.setValue}
                mixType={OrderMixType.Primary}
                loadingItems={loadingItems}
                items={items}
            />
            {/* Secondary Mix fields */}
            <OrderMixEntriesForm
                control={props.control}
                getValues={props.getValues}
                setValue={props.setValue}
                mixType={OrderMixType.Secondary}
                loadingItems={loadingItems}
                items={items}
            />
        </>
    )
}

export default OrderExtrusionForm;
