import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import React from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import App from '../App'
import {GlobalHistory} from '../lib/globalHistory'

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error("Couldn't find root element")
const root = createRoot(rootElement)

root.render(
    <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <GlobalHistory/>
            <App/>
        </LocalizationProvider>
    </BrowserRouter>
)