import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { IFormProps } from "../../lib/form";
import { IEmployee } from "../../models/employee";
import { IGeneralInfo } from "../../models/generalInfo";
import { IPayroll, Payroll } from "../../models/payroll";
import { IReport } from "../../models/report";
import { RootStoreContext } from "../../stores/root.store";
import FormSectionTitle from "../Form/FormSectionTitle.component";
import FormDialogTitle from "../FormDialogTitle.component";
import PayrollEntriesForm from "./PayrollEntriesForm";
import DownloadIcon from '@mui/icons-material/Download';

const PayrollForm = (props: IFormProps) => {
    const { apiStore } = useContext(RootStoreContext);

    const [loading, setLoading] = useState(true);
    const [loadingReports, setLoadingReports] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const { control, handleSubmit, reset, setValue, getValues } = useForm<IPayroll>({
        defaultValues: new Payroll()
    });

    const [infos, setInfos] = useState<IGeneralInfo[]>([]);
    const [employees, setEmployees] = useState<IEmployee[]>([]);
    const [reports, setReports] = useState<IReport[]>([]);

    const originalPayroll = useRef<IPayroll | null>(null);

    const infoIdWatcher = useWatch({ control, name: 'general_info_id' });
    const dateWatcher = useWatch({ control, name: 'payroll_date' });

    const firstTimeReports = useRef(true);

    const handleGeneralInfoChange = (data: IGeneralInfo | null) => {
        setValue(`general_info`, data);
        setValue(`general_info_id`, data?.id || 0);
    }

    const onSubmit: SubmitHandler<IPayroll> = async (values) => {
        try {
            setSubmitting(true);

            if (originalPayroll.current) {
                const currentEntriesIds = values.payroll_entries.map(e => e.id).filter(id => id > 0);
                originalPayroll.current.payroll_entries
                    .forEach(e => {
                        if (!currentEntriesIds.includes(e.id)) {
                            values.payroll_entries.push({ ...e, _destroy: true });
                        } else {
                            const entry = values.payroll_entries.find(x => x.id === e.id);
                            if (entry) {
                                const currentDaysIds = entry.payroll_entry_days.map(d => d.id).filter(id => id > 0);
                                e.payroll_entry_days.forEach(d => {
                                    if (!currentDaysIds.includes(d.id)) {
                                        entry.payroll_entry_days.push({ ...d, _destroy: true });
                                    }
                                })
                            }
                        }
                    })
            }

            await apiStore.submitPayroll(values, props.id);
            props.onSubmit();
        } catch (e) {
            console.log(e);
        } finally {
            setSubmitting(false);
        }
    }

    const onDownloadClick = useCallback(() => {
        const values = getValues();
        apiStore.downloadPayrollExcelFromObject(values);
    }, [getValues]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (props.id > 0) {
                    const data = await apiStore.getPayroll(props.id);
                    originalPayroll.current = data;
                    reset(data);
                }
                setInfos(await apiStore.getGeneralInfos());
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.id]);

    useEffect(() => {
        const selectedInfo = infos.find(i => i.id === infoIdWatcher);
        if (selectedInfo === undefined) {
            setEmployees([]);
        } else {
            const emp = selectedInfo.general_info_entries.map(e => e.employee!).flat();
            setEmployees(emp);
        }
    }, [infoIdWatcher, infos]);

    useEffect(() => {
        if (firstTimeReports.current) {
            firstTimeReports.current = false;
            return;
        }

        (async () => {
            try {
                setLoadingReports(true);
                const myDate = dayjs(dateWatcher);
                setReports(await apiStore.getReportsByMonth(myDate.month() + 1, myDate.year()));
            } catch (e) {
                console.log(e);
            } finally {
                setLoadingReports(false);
            }
        })();
    }, [dateWatcher]);

    return (
        <>
            <DialogTitle>
                <FormDialogTitle
                    edit={props.id > 0}
                    loading={loading || loadingReports}
                    onClose={props.onClose}
                    title="Payroll"
                />
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    <FormSectionTitle title={"General"} />
                    <Grid item xs={12} sm={6} md={4}>
                        <Controller
                            control={control}
                            name={"payroll_name"}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={"Name"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Controller
                            control={control}
                            name={"general_info"}
                            render={({ field: { value } }) => (
                                <Autocomplete
                                    options={infos}
                                    value={value}
                                    isOptionEqualToValue={(op, v) => op.id === v.id}
                                    getOptionLabel={op => op.name}
                                    onChange={(_, data) => handleGeneralInfoChange(data)}
                                    renderInput={params =>
                                        <TextField
                                            {...params}
                                            label={"General Info"}
                                        />
                                    }
                                />
                            )} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Controller
                            control={control}
                            name={"payroll_date"}
                            render={({ field: { value, ...field } }) => (
                                <DatePicker
                                    {...field}
                                    value={dayjs(value)}
                                    label={"Date"}
                                    slotProps={{ textField: { fullWidth: true } }}
                                    format={"DD-MM-YYYY"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<DownloadIcon />}
                            onClick={onDownloadClick}
                        >
                            Download Excel
                        </Button>
                    </Grid>
                    <PayrollEntriesForm
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        employees={employees}
                        reports={reports}
                        generalInfos={infos}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} disabled={submitting} color={"secondary"}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={submitting}>
                    Submit
                </Button>
            </DialogActions>
        </>
    )
}

export default PayrollForm;
