import { makeAutoObservable } from "mobx";
import { RootStore } from "./root.store";
import { IReport } from "../models/report";

export class FormStore {
    open: boolean = false;
    id: number = 0;
    rootStore: RootStore;
    baseReport: IReport | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    handleNew = () => {
        this.id = 0;
        this.open = true;
        this.baseReport = null;
    }

    handleOpen = (id: number) => {
        this.id = id;
        this.open = true;
        this.baseReport = null;
    }

    // Reports    
    handleOpenWithBaseReport = (baseReport: IReport) => {
        this.baseReport = baseReport;
        this.id = 0;
        this.open = true;
    }

    handleClose = () => {
        this.open = false;
        this.baseReport = null;
    }

    onSubmit = (loadData: () => void) => {
        loadData();
        this.handleClose();
    }
}