import {Grid, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {Control, Controller, UseFormGetValues, UseFormSetValue, useWatch} from "react-hook-form";
import {IReport} from "../../models/report";

interface Props {
    control: Control<IReport>;
    fieldIndex: number;
    setValue: UseFormSetValue<IReport>;
    getValues: UseFormGetValues<IReport>;
}

const ReportEntryDaysFormTop = (props: Props) => {
    const workedHoursWatcher = useWatch({
        control: props.control,
        name: `report_entries.${props.fieldIndex}.worked_hours`
    });

    const nightHoursWatcher = useWatch({
        control: props.control,
        name: `report_entries.${props.fieldIndex}.night_hours`
    });

    const weekHoursWatcher = useWatch({control: props.control, name: 'week_hours'});

    useEffect(() => {
        const values = props.getValues();
        const entry = values.report_entries[props.fieldIndex];
        props.setValue(`report_entries.${props.fieldIndex}.extra_hours`, entry.worked_hours - weekHoursWatcher - nightHoursWatcher);
    }, [weekHoursWatcher, workedHoursWatcher, nightHoursWatcher]);

    return (
        <Grid container spacing={1}>
            <Grid item>
                <Controller
                    control={props.control}
                    name={`report_entries.${props.fieldIndex}.worked_hours`}
                    render={({field}) => (
                        <Typography>
                            <strong>Worked: {field.value.toFixed(2)}</strong>
                        </Typography>
                    )}
                />
            </Grid>
            <Controller
                control={props.control}
                name={`report_entries.${props.fieldIndex}.extra_hours`}
                render={({field}) => (
                    <Grid item sx={{display: field.value <= 0 ? "none" : "block"}}>
                        <Typography>
                            <strong>Extra: {field.value.toFixed(2)}</strong>
                        </Typography>
                    </Grid>
                )}
            />
            <Controller
                control={props.control}
                name={`report_entries.${props.fieldIndex}.double_hours`}
                render={({field}) => (
                    <Grid item sx={{display: field.value <= 0 ? "none" : "block"}}>
                        <Typography>
                            <strong>Double: {field.value.toFixed(2)}</strong>
                        </Typography>
                    </Grid>
                )}
            />
            <Controller
                control={props.control}
                name={`report_entries.${props.fieldIndex}.night_hours`}
                render={({field}) => (
                    <Grid item sx={{display: field.value <= 0 ? "none" : "block"}}>
                        <Typography>
                            <strong>Night: {field.value.toFixed(2)}</strong>
                        </Typography>
                    </Grid>
                )}
            />
        </Grid>
    )
}

export default ReportEntryDaysFormTop;
