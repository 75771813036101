import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export const getYears = (actualDate: Date | null, date: Date | null | undefined): number => {
    if (actualDate === null || date === null || date === undefined) {
        return 0;
    }
    return dayjs(actualDate).diff(date, 'years', true);
}

export const getDayDateWithName = (date: Date | null) => {
    if (date === null) {
        return "";
    }
    return dayjs(date).format("dddd DD, MMMM YYYY")
}

export const isSameDate = (date1: Dayjs, date2: Dayjs): boolean => {
    return date1.isSame(dayjs(date2), 'day');
}

export const isDateBetweenRange = (myDate: Dayjs, startDate: Dayjs, endDate: Dayjs): boolean => {
    return myDate.isBetween(dayjs(startDate), dayjs(endDate), null, '[]');
}
