import React from "react";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Control, Controller, useFieldArray, UseFormSetValue } from "react-hook-form";
import { IPayroll, PayrollEntryDay } from "../../models/payroll";
import BorderBlock from "../BorderBlock.component";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { IReport } from "../../models/report";
import PayrollEntryDaySalaryForm from "./PayrollEntryDaySalaryForm";
import PayrollEntryDayTitleForm from "./PayrollEntryDayTitleForm";
import { IGeneralInfo } from "../../models/generalInfo";

interface Props {
    control: Control<IPayroll>;
    entryIndex: number;
    reports: IReport[];
    setValue: UseFormSetValue<IPayroll>;
    generalInfos: IGeneralInfo[];
}

const PayrollEntryDaysForm = (props: Props) => {
    const { fields, append, remove } = useFieldArray({
        control: props.control,
        name: `payroll_entries.${props.entryIndex}.payroll_entry_days`
    });

    const handleAddDay = () => {
        append(new PayrollEntryDay());
    }

    const handleRemoveDay = (index: number) => {
        remove(index);
    }

    return (
        <>
            <Grid item container spacing={1} direction={"column"}>
                <Grid item container spacing={2} alignItems={'center'}>
                    <Grid item>
                        <Typography variant={"h6"}>
                            Days
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleAddDay}>
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>
            <Grid item container spacing={3} alignItems={"center"}>
                {fields.map((field, index) => (
                    <Grid item xs={12} md={6} lg={4} key={`payroll-entry-days-${props.entryIndex}-${index}-${field.id}`}>
                        <BorderBlock
                            title={(
                                <PayrollEntryDayTitleForm
                                    control={props.control}
                                    entryIndex={props.entryIndex}
                                    fieldIndex={index}
                                    reports={props.reports}
                                    setValue={props.setValue}
                                    generalInfos={props.generalInfos}
                                />
                            )}
                            onRemoveClick={() => handleRemoveDay(index)}>
                            <Grid container spacing={3} alignItems={'center'}>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        control={props.control}
                                        name={`payroll_entries.${props.entryIndex}.payroll_entry_days.${index}.entry_date`}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                value={dayjs(field.value)}
                                                label={"Date"}
                                                slotProps={{ textField: { fullWidth: true } }}
                                                format={"DD-MM-YYYY"}
                                            />
                                        )}
                                    />
                                </Grid>
                                <PayrollEntryDaySalaryForm
                                    control={props.control}
                                    entryIndex={props.entryIndex}
                                    fieldIndex={index}
                                    reports={props.reports}
                                />
                            </Grid>
                        </BorderBlock>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default PayrollEntryDaysForm;
