import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Control, Controller, UseFormGetValues, UseFormSetValue, useWatch } from "react-hook-form";
import { IOrder } from "../../../models/order";
import FormSectionTitle from "../../Form/FormSectionTitle.component";

interface Props {
    control: Control<IOrder>;
    setValue: UseFormSetValue<IOrder>;
    getValues: UseFormGetValues<IOrder>;
}

const OrderGeneralInfoForm = (props: Props) => {
    const widthWatcher = useWatch({ control: props.control, name: 'reference_width' });
    const heightWatcher = useWatch({ control: props.control, name: 'reference_height' });
    const grammageWatcher = useWatch({ control: props.control, name: 'reference_grammage' });
    const sidesWatcher = useWatch({ control: props.control, name: 'reference_sides' });
    const bellowsWatcher = useWatch({ control: props.control, name: 'reference_bellows' });
    const requestedAmountWatcher = useWatch({ control: props.control, name: "general_info_requested_amount" });
    const wastePercWatcher = useWatch({ control: props.control, name: "general_info_waste_percentage" });
    const refilePercWatcher = useWatch({ control: props.control, name: "general_info_refile_percentage" });
    const weightPerRollWatcher = useWatch({ control: props.control, name: "final_product_weight_per_roll" });

    useEffect(() => {
        const values = props.getValues();

        const referenceWidth = Number(values.reference_width);
        const referenceHeight = Number(values.reference_height);
        const referenceBellows = Number(values.reference_bellows);
        const referenceGrammage = Number(values.reference_grammage);
        const referenceSides = Number(values.reference_sides);
        const requestedAmount = Number(requestedAmountWatcher);
        const wastedPerc = Number(wastePercWatcher);
        const refilePerc = Number(refilePercWatcher);
        const weightPerRoll = Number(weightPerRollWatcher);

        const weightMax = (((referenceWidth + referenceBellows) * referenceHeight * referenceGrammage) * referenceSides) / 10000;

        const programmedKgs = (weightMax * requestedAmount) / 1000;

        const waste = programmedKgs * (wastedPerc / 100);
        const refile = programmedKgs * (refilePerc / 100);

        const total = programmedKgs + waste + refile;

        props.setValue("general_info_thousand_weight_max", weightMax);
        props.setValue("general_info_thousand_weight_min", weightMax - 0.02);

        props.setValue("general_info_programmed_kilograms", programmedKgs);
        props.setValue("general_info_waste", waste);
        props.setValue("general_info_refile", refile);

        props.setValue("general_info_programmed_total", total);
        props.setValue("general_info_rolls_amount", total / weightPerRoll);

    }, [widthWatcher, heightWatcher, grammageWatcher, sidesWatcher, bellowsWatcher, requestedAmountWatcher,
        wastePercWatcher, refilePercWatcher, weightPerRollWatcher]);

    return (
        <>
            <FormSectionTitle title={"General Info."} />
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"final_product_weight_per_roll"}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Weight Per Roll (Kgs)"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"final_product_weight_per_core"}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Weight Per Core (Kgs)"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    name={"general_info_requested_amount"}
                    control={props.control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Requested Amount (Units)"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
                <Controller
                    name={"general_info_waste_percentage"}
                    control={props.control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Waste %"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
                <Controller
                    name={"general_info_refile_percentage"}
                    control={props.control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Refile %"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            {/* Computed values */}
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell variant={"head"}>Min. Thousand Weight</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'general_info_thousand_weight_min'}
                                        render={({ field: { value } }) => (
                                            <span>{value.toFixed(2)} grs.</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Max. Thousand Weight</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'general_info_thousand_weight_max'}
                                        render={({ field: { value } }) => (
                                            <span>{value.toFixed(2)} grs.</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Programmed Kilograms</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'general_info_programmed_kilograms'}
                                        render={({ field: { value } }) => (
                                            <span>{value.toFixed(2)} kgs.</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={"head"}>Waste</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'general_info_waste'}
                                        render={({ field: { value } }) => (
                                            <span>{value.toFixed(4)}</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Refile</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'general_info_refile'}
                                        render={({ field: { value } }) => (
                                            <span>{value.toFixed(4)}</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Total</TableCell>
                                <TableCell variant={"head"}>
                                    <Controller
                                        control={props.control}
                                        name={'general_info_programmed_total'}
                                        render={({ field: { value } }) => (
                                            <span>{value.toFixed(2)} kgs.</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={"head"}>Rolls</TableCell>
                                <TableCell variant={"head"}>
                                    <Controller
                                        control={props.control}
                                        name={'general_info_rolls_amount'}
                                        render={({ field: { value } }) => (
                                            <span>{value.toFixed(4)} rolls</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default OrderGeneralInfoForm;
