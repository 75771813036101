import DownloadIcon from '@mui/icons-material/Download';
import {
    Autocomplete,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { IFormProps } from "../../lib/form";
import { IEmployee } from "../../models/employee";
import { IGeneralInfo } from "../../models/generalInfo";
import { IReport, IReportEntryDay, Report, ReportEntryDay } from "../../models/report";
import { RootStoreContext } from "../../stores/root.store";
import FormSectionTitle from "../Form/FormSectionTitle.component";
import FormDialogTitle from "../FormDialogTitle.component";
import TabPanel, { a11yProps } from "../TabPanel.component";
import ReportDetailsForm from "./ReportDetailsForm";
import ReportEntriesForm from "./ReportEntriesForm";
import ReportHoursTableForm from "./ReportHoursTableForm";

const ReportForm = (props: IFormProps) => {
    const {apiStore} = useContext(RootStoreContext);
    const originalReport = useRef<IReport | null>(null);

    const {control, reset, handleSubmit, setValue, getValues} = useForm<IReport>({
        defaultValues: props.baseReport !== null ? props.baseReport : new Report()
    })

    const startDateWatcher = useWatch({control, name: 'start_date'});
    const endDateWatcher = useWatch({control, name: 'end_date'});

    const [tabValue, setTabValue] = useState(0);
    const [employees, setEmployees] = useState<IEmployee[]>([]);
    const [infos, setInfos] = useState<IGeneralInfo[]>([]);

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }

    const handleGeneralInfoChange = (data: IGeneralInfo | null) => {
        setValue(`general_info`, data);
        setValue(`general_info_id`, data?.id || 0);
    }

    const handleDownloadExcel = async () => {
        const values = getValues();
        try {
            await apiStore.downloadReportExcel(values);
        } catch (e) {
            console.log(e);
        }
    }

    const onSubmit: SubmitHandler<IReport> = async (values) => {
        try {
            setSubmitting(true);
            if (originalReport.current) {
                const currentEntriesIds = values.report_entries
                    .map(e => e.id)
                    .filter(e => e > 0);

                originalReport.current.report_entries
                    .forEach(e => {
                        if (!currentEntriesIds.includes(e.id)) {
                            values.report_entries
                                .push({...e, _destroy: true});
                        } else {
                            const entry = values.report_entries
                                .find(x => x.id === e.id);

                            if (entry) {
                                const currentDaysIds = entry.report_entry_days
                                    .map(d => d.id)
                                    .filter(d => d > 0);

                                e.report_entry_days
                                    .forEach(d => {
                                        if (!currentDaysIds.includes(d.id)) {
                                            entry.report_entry_days
                                                .push({...d, _destroy: true});
                                        } else {
                                            const day = entry.report_entry_days
                                                .find(x => x.id === d.id);

                                            if (day) {
                                                const currentEventsIds = day.report_entry_day_events
                                                    .map(de => de.id)
                                                    .filter(de => de > 0);

                                                d.report_entry_day_events
                                                    .forEach(de => {
                                                        if (!currentEventsIds.includes(de.id)) {
                                                            day.report_entry_day_events
                                                                .push({...de, _destroy: true});
                                                        }
                                                    })
                                            }
                                        }
                                    });
                            }
                        }
                    });
            }
            await apiStore.submitReport(values, props.id);
            props.onSubmit();
        } catch (e) {
            console.log(e);
        } finally {
            setSubmitting(false);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                if (props.id > 0) {
                    const data = await apiStore.getReport(props.id);
                    originalReport.current = data;
                    reset(data);
                }
                setEmployees(await apiStore.getEmployees());
                setInfos(await apiStore.getGeneralInfos());
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.id]);

    useEffect(() => {
        const values = getValues();

        const startDate = dayjs(values.start_date);
        const endDate = dayjs(values.end_date);

        const entries = values.report_entries.slice();

        const daysInBetween = Math.ceil(dayjs(endDate).diff(startDate, 'day', true));

        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            const newDays: IReportEntryDay[] = [];

            for (let j = 0; j <= daysInBetween; j++) {
                const currentDate = startDate.add(j, 'day');
                const entryDay = entry.report_entry_days.find(x => {
                    const dayDate = dayjs(x.entry_date);
                    return dayDate.month() === currentDate.month() && dayDate.date() === currentDate.date();
                });
                if (entryDay && entryDay.report_entry_day_events.length > 0) {
                    newDays.push(entryDay);
                } else {
                    newDays.push(new ReportEntryDay({entry_date: currentDate.toDate()}));
                }
            }

            setValue(`report_entries.${i}.report_entry_days`, newDays.slice());
        }
    }, [startDateWatcher, endDateWatcher]);

    return (
        <>
            <DialogTitle>
                <FormDialogTitle
                    edit={props.id > 0}
                    loading={loading}
                    onClose={props.onClose}
                    title="Report"
                />
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} alignItems={"center"}>
                    <FormSectionTitle title={"General"}/>
                    <Grid item xs={12} md={6}>
                        <Controller
                            control={control}
                            name={"name"}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label={"Name"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            control={control}
                            name={"general_info"}
                            render={({field: {value}}) => (
                                <Autocomplete
                                    options={infos}
                                    value={value}
                                    isOptionEqualToValue={(op, v) => op.id === v.id}
                                    getOptionLabel={op => op.name}
                                    onChange={(_, data) => handleGeneralInfoChange(data)}
                                    renderInput={params =>
                                        <TextField label={"General Info"} {...params} />
                                    }
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Controller
                            control={control}
                            name={"week_hours"}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    label={"Week Hours"}
                                    type={"number"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Controller
                            control={control}
                            name={"start_date"}
                            render={({field: {value, ...field}}) => (
                                <DatePicker
                                    {...field}
                                    value={dayjs(value)}
                                    label={"Start Date"}
                                    slotProps={{textField: {fullWidth: true}}}
                                    format={"DD-MM-YYYY"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Controller
                            control={control}
                            name={"end_date"}
                            render={({field: {value, ...field}}) => (
                                <DatePicker
                                    {...field}
                                    label={"End Date"}
                                    value={dayjs(value)}
                                    slotProps={{textField: {fullWidth: true}}}
                                    format={"DD-MM-YYYY"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Controller
                            control={control}
                            name={"report_date"}
                            render={({field: {value, ...field}}) => (
                                <DatePicker
                                    {...field}
                                    label={"Report Date"}
                                    value={dayjs(value)}
                                    slotProps={{textField: {fullWidth: true}}}
                                    format={"DD-MM-YYYY"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            onClick={handleDownloadExcel}
                            color={"success"}
                            variant={"contained"}
                            startIcon={<DownloadIcon/>}
                        >
                            Download Excel
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="Employees" {...a11yProps(0)} />
                                <Tab label="Hours" {...a11yProps(1)} />
                                <Tab label="Details" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TabPanel value={tabValue} index={0}>
                            <ReportEntriesForm
                                control={control}
                                employees={employees}
                                getValues={getValues}
                                setValue={setValue}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <ReportHoursTableForm
                                control={control}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <ReportDetailsForm
                                control={control}
                            />
                        </TabPanel>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color={"secondary"} disabled={submitting}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={submitting}>
                    Submit
                </Button>
            </DialogActions>
        </>
    )
}

export default ReportForm;
