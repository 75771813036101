import {
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import React, {useContext, useEffect, useState} from "react";
import {RootStoreContext} from "../../../stores/root.store";
import {IMonthlyReportEntry} from "../../../models/report";

const ReportsMonthlyLogContent = () => {
    const {apiStore} = useContext(RootStoreContext);
    const [currentDate, setCurrentDate] = useState<Dayjs | null>(dayjs());
    const [entries, setEntries] = useState<IMonthlyReportEntry[]>([]);

    useEffect(() => {
        (async () => {
            setEntries(await apiStore.getMonthlyReport(currentDate));
        })();
    }, [currentDate]);

    return (
        <Grid container spacing={3} direction="column">
            <Grid item container spacing={1} direction="column">
                <Grid item sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <DatePicker
                        views={['month', 'year']}
                        label={"Month & Year"}
                        value={currentDate}
                        onChange={(newValue) => setCurrentDate(newValue)}
                        disableFuture
                    />
                </Grid>
                <Grid item>
                    <Divider/>
                </Grid>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Employee</TableCell>
                                <TableCell>Worked Hours</TableCell>
                                <TableCell>Extra Hours</TableCell>
                                <TableCell>Double Hours</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entries.length > 0 ? (
                                entries.map((entry, entryIndex) => (
                                    <TableRow key={`entry-row-${entry.employee_id}-${entryIndex}`}>
                                        <TableCell>
                                            {entry.employee_name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {entry.worked_hours.toFixed(2)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {entry.extra_hours.toFixed(2)}
                                        </TableCell>
                                        <TableCell align="right">
                                            {entry.double_hours.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell align="center" colSpan={4}>
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default ReportsMonthlyLogContent;

