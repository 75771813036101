import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { formatDate } from "../../lib/format";
import { EntityType } from "../../lib/util";
import { IPayroll } from "../../models/payroll";
import { RootStoreContext } from "../../stores/root.store";
import FormDialog from "../Form/FormDialog.component";
import IndexTopSection from "../IndexTopSection.component";
import TableActions from "../Table/TableActions.component";
import PayrollForm from "./PayrollForm";

const PayrollsIndex = () => {
    const { apiStore } = useContext(RootStoreContext);

    const [loading, setLoading] = useState(true);
    const [reloading, setReloading] = useState(false);
    const [payrolls, setPayrolls] = useState<IPayroll[]>([]);

    const columns = useMemo<MRT_ColumnDef<IPayroll>[]>(() => [
        {
            header: 'Name',
            accessorKey: 'payroll_name'
        },
        {
            header: 'Date',
            accessorKey: 'payroll_date',
            Cell: ({ cell }) => formatDate(cell.getValue<Date>())
        },
        {
            header: 'Last Update',
            accessorKey: 'updated_at',
            Cell: ({ cell }) => formatDate(cell.getValue<Date>(), true)
        }
    ], []);

    const handleReload = useCallback(() => setReloading(true), []);

    const handleDownloadExcel = useCallback(async (id: number) => {
        try {
            await apiStore.downloadPayrollExcel(id);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        if (loading || reloading) {
            (async () => {
                try {
                    setLoading(true);
                    setPayrolls(await apiStore.getPayrolls());
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoading(false);
                    setReloading(false);
                }
            })();
        }
    }, [reloading]);

    const table = useMaterialReactTable({
        columns,
        data: payrolls,
        enableRowActions: true,
        positionActionsColumn: "last",
        autoResetPageIndex: false,
        enableFullScreenToggle: false,
        renderRowActions: ({ row }) => (
            <TableActions
                id={row.original.id}
                onDelete={handleReload}
                onDuplicate={handleReload}
                entityType={EntityType.Payroll}
                download={() => handleDownloadExcel(row.original.id)}
            />
        ),
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 25
            }
        },
        state: {
            isLoading: loading
        },
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100],
        }
    })

    return (
        <>
            <IndexTopSection />
            <MaterialReactTable table={table} />
            <FormDialog
                content={PayrollForm}
                loadData={handleReload}
                fullScreen
            />
        </>
    )
}

export default PayrollsIndex;
