import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { IFormProps } from "../../lib/form";
import { getYears } from "../../lib/time";
import { IEmployee } from "../../models/employee";
import { GeneralInfo, GeneralInfoEntry, IGeneralInfo } from "../../models/generalInfo";
import { RootStoreContext } from '../../stores/root.store';
import FormSectionTitle from '../Form/FormSectionTitle.component';
import FormDialogTitle from '../FormDialogTitle.component';
import GeneralInfoEntryForm from './GeneralInfoEntryForm';

const GeneralInfoForm = (props: IFormProps) => {
    const { apiStore } = useContext(RootStoreContext);

    const originalInfo = useRef<IGeneralInfo | null>(null);

    const { control, reset, handleSubmit, setValue, getValues } = useForm<IGeneralInfo>({
        defaultValues: new GeneralInfo()
    });

    const [employees, setEmployees] = useState<IEmployee[]>([]);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const { fields: entryFields, append: entryAppend, remove: entryRemove } = useFieldArray({
        name: 'general_info_entries',
        control
    });

    const handleAddEntry = () => {
        entryAppend(new GeneralInfoEntry());
    }

    const handleRemoveEntry = (index: number) => {
        entryRemove(index);
    }

    const handleSalaryChange = (value: number, index: number) => {
        const values = getValues();
        const entry = values.general_info_entries[index];

        const years = getYears(values.report_date, entry.employee?.join_date || null);
        const dailySalary = value / 23.83;

        setValue(`general_info_entries.${index}.salary`, value);
        setValue(`general_info_entries.${index}.daily_salary`, dailySalary);
        setValue(`general_info_entries.${index}.yearly_salary`, value * 12);

        const vacations = (years >= 5 ? (entry.daily_salary * 18) : (years >= 1 ? entry.daily_salary * 14 : 0));

        setValue(`general_info_entries.${index}.vacations`, vacations);

        const vacationsValue = vacations / 23.83;

        let vacationsBonus = 0;
        if (years >= 15) {
            vacationsBonus = value * 0.5;
        } else if (years >= 9) {
            vacationsBonus = value * 0.4166;
        } else if (years >= 4) {
            vacationsBonus = value * 0.3333;
        } else if (years >= 1) {
            vacationsBonus = value * 0.25;
        }
        setValue(`general_info_entries.${index}.vacations_bonus`, vacationsBonus);
        setValue(`general_info_entries.${index}.easter_royalty`, years >= 1 ? value : value * years);

        const firstBonus = years < 1 ? 0 : (years > 3 ? dailySalary * 60 : dailySalary * 45);
        const secondBonus = (years < 1 ? 0 : (years >= 3 ? vacationsValue * 60 : vacationsValue * 45)) / 12;

        setValue(`general_info_entries.${index}.first_bonus`, firstBonus);
        setValue(`general_info_entries.${index}.second_bonus`, secondBonus);
        setValue(`general_info_entries.${index}.bonus`, firstBonus + secondBonus);

        setValue(`general_info_entries.${index}.afp_retention`, value * 0.0287);
        setValue(`general_info_entries.${index}.sfs_retention`, value * 0.0304);
        setValue(`general_info_entries.${index}.contribution_retention`, value * 0.0591);

        setValue(`general_info_entries.${index}.afp_payment`, value * 0.0710);
        setValue(`general_info_entries.${index}.sfs_payment`, value * 0.0709);
        setValue(`general_info_entries.${index}.arl_payment`, value * 0.0110);
        setValue(`general_info_entries.${index}.infotep_payment`, value * 0.01);
        setValue(`general_info_entries.${index}.contribution_payment`, value * 0.1629);
        setValue(`general_info_entries.${index}.tss_payment`, value * 0.2220);
    }

    const handleEmployeeChange = (value: IEmployee | null, index: number) => {
        setValue(`general_info_entries.${index}.employee`, value);
        setValue(`general_info_entries.${index}.employee_id`, value?.id || 0);
    }

    const onSubmit: SubmitHandler<IGeneralInfo> = async (values) => {
        setSubmitting(true);

        if (originalInfo.current) {
            const currentEntriesIds = values.general_info_entries.map(e => e.id).filter(e => e > 0);
            originalInfo.current.general_info_entries
                .forEach(e => {
                    if (!currentEntriesIds.includes(e.id)) {
                        values.general_info_entries.push({ ...e, _destroy: true });
                    } else {
                        const entry = values.general_info_entries.find(x => x.id === e.id);
                        if (entry) {
                            const currentRetentionsIds = entry.general_info_entry_additional_retentions.map(r => r.id).filter(r => r > 0);
                            e.general_info_entry_additional_retentions
                                .forEach(r => {
                                    if (!currentRetentionsIds.includes(r.id)) {
                                        entry.general_info_entry_additional_retentions.push({ ...r, _destroy: true });
                                    }
                                })
                        }
                    }
                });
        }

        try {
            await apiStore.submitGeneralInfo(values, props.id);
            props.onSubmit();
        } catch (e) {
            console.log(e);
        } finally {
            setSubmitting(false);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                setEmployees(await apiStore.getEmployees());

                if (props.id > 0) {
                    const data = await apiStore.getGeneralInfo(props.id);
                    originalInfo.current = data;
                    reset(data);
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.id]);

    return (
        <>
            <DialogTitle>
                <FormDialogTitle
                    edit={props.id > 0}
                    loading={loading}
                    onClose={props.onClose}
                    title='Info'
                />
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    <FormSectionTitle title={"General"} />
                    <Grid item xs={12} md={8}>
                        <Controller
                            control={control}
                            name={"name"}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={"Name"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            control={control}
                            name={"report_date"}
                            render={({ field: { value, ...field } }) => (
                                <DatePicker
                                    {...field}
                                    value={dayjs(value)}
                                    label={"Report Date"}
                                    slotProps={{ textField: { fullWidth: true } }}
                                    format={"DD-MM-YYYY"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid container item spacing={1} direction={"column"}>
                        <Grid container item spacing={3} justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <Typography variant={"h6"}>
                                    General
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={"contained"}
                                    onClick={handleAddEntry}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </Grid>
                    {entryFields.map((field, fieldIndex) => (
                        <Grid item xs={12} key={field.id}>
                            <GeneralInfoEntryForm
                                control={control}
                                employees={employees}
                                field={field}
                                fieldIndex={fieldIndex}
                                handleRemoveEntry={handleRemoveEntry}
                                handleEmployeeChange={handleEmployeeChange}
                                handleSalaryChange={handleSalaryChange}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color={"secondary"} disabled={submitting}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={submitting}>
                    Submit
                </Button>
            </DialogActions>
        </>
    )
}

export default GeneralInfoForm;
