export interface IEmployee {
    id: number;
    fpc_id: number;
    formatted_fpc_id: string;
    name: string;
    last_name: string;
    full_name: string;
    group: Employee.Group;
    department: Employee.Department;
    position: Employee.Position;
    join_date: Date | null;
    formatted_join_date: string;
    created_at?: Date;
    updated_at?: Date;
    formatted_updated_at: string;
}

export class Employee implements IEmployee {
    id: number = 0;
    fpc_id: number = 0;
    formatted_fpc_id: string = "FPC-0000";
    name: string = "";
    last_name: string = "";
    full_name: string = "";
    group: Employee.Group = Employee.Group.None;
    department: Employee.Department = Employee.Department.None;
    position: Employee.Position = Employee.Position.None;
    join_date: Date | null = null;
    formatted_join_date: string = "";
    created_at?: Date;
    updated_at?: Date;
    formatted_updated_at: string = "";

    constructor(init?: Partial<IEmployee>) {
        Object.assign(this, init);
    }
}

export namespace Employee {
    export enum Group {
        None = 0,
        Administrative = 1,
        Operations = 2
    }

    export enum Department {
        None = 0,
        Administration = 1,
        Production = 2
    }

    export enum Position {
        None = 0,
        GeneralManager = 1,
        AdministrativeAssistant = 2,
        OperationsSupervisor = 3,
        ExtruderOperator = 4,
        FlexoOperator = 5,
        CutOperator = 6,
        Concierge = 7,
        Operator = 8,
        Receptionist = 9,
    }
}
