import dayjs from "dayjs";
import {IEmployee} from "./employee";
import {IGeneralInfo} from "./generalInfo";

export interface IReport {
    id: number
    name: string
    report_date: Date | null
    report_entries: IReportEntry[];
    start_date: Date | null;
    end_date: Date | null;
    general_info_id: number;
    general_info: IGeneralInfo | null;
    week_hours: number;
    updated_at: Date | null;
}

export class Report implements IReport {
    id: number = 0
    name: string = ""
    report_date: Date | null = dayjs().toDate()
    start_date: Date | null = dayjs().subtract(7, 'day').toDate()
    end_date: Date | null = dayjs().toDate()
    report_entries: IReportEntry[] = [];
    general_info_id: number = 0;
    general_info: IGeneralInfo | null = null;
    week_hours: number = 44;
    updated_at: Date | null = null;

    constructor(init?: Partial<IReport>) {
        Object.assign(this, init)
    }
}

export interface IReportEntry {
    id: number;
    employee_id: number;
    employee: IEmployee | null;
    report_id: number;
    _destroy: boolean;
    worked_hours: number;
    extra_hours: number;
    double_hours: number;
    night_hours: number;
    report_entry_days: IReportEntryDay[];
    total_amount: number;
    daily_salary: number;
    hourly_salary: number;
    percent_35: number;
    percent_15: number;
    double_salary: number;
}

export class ReportEntry implements IReportEntry {
    id: number = 0;
    employee_id: number = 0;
    employee: IEmployee | null = null;
    report_id: number = 0;
    _destroy: boolean = false;
    worked_hours: number = 0;
    extra_hours: number = 0;
    double_hours: number = 0;
    night_hours: number = 0;
    report_entry_days: IReportEntryDay[] = [];
    total_amount: number = 0;
    daily_salary: number = 0;
    hourly_salary: number = 0;
    double_salary: number = 0;
    percent_15: number = 0;
    percent_35: number = 0;

    constructor(init?: Partial<IReportEntry>) {
        Object.assign(this, init);
    }
}

export interface IReportEntryDay {
    id: number;
    entry_date: Date;
    _destroy: boolean;
    worked_hours: number;
    extra_hours: number;
    double_hours: number;
    night_hours: number;
    report_entry_day_events: IReportEntryDayEvent[];
    is_holiday: boolean;
}

export class ReportEntryDay implements IReportEntryDay {
    id: number = 0;
    entry_date: Date = dayjs().toDate();
    _destroy: boolean = false;
    worked_hours: number = 0;
    extra_hours: number = 0;
    double_hours: number = 0;
    night_hours: number = 0;
    report_entry_day_events: IReportEntryDayEvent[] = [];
    is_holiday: boolean = false;

    constructor(init?: Partial<IReportEntryDay>) {
        Object.assign(this, init);
    }
}

export interface IReportEntryDayEvent {
    id: number;
    report_entry_day_id: number;
    start_time: Date | null;
    end_time: Date | null;
    _destroy: boolean;
}

export class ReportEntryDayEvent implements IReportEntryDayEvent {
    id: number = 0;
    report_entry_day_id: number = 0;
    start_time: Date | null = null;
    end_time: Date | null = null;
    _destroy: boolean = false;

    constructor(init?: Partial<IReportEntryDayEvent>) {
        Object.assign(this, init);
    }
}

export interface IMonthlyReportEntry {
    employee_id: number;
    employee_name: string;
    worked_hours: number;
    extra_hours: number;
    double_hours: number;
}
