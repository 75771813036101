import { makeAutoObservable } from "mobx";
import { RootStore } from "./root.store";

export class AuthStore {
    authenticated: boolean;
    token: string;
    userEmail: string;
    darkMode: boolean;
    rootStore: RootStore;

    constructor(rootStore: RootStore, token: string) {
        this.rootStore = rootStore;
        this.token = token;
        this.authenticated = this.token !== "";
        this.userEmail = "";
        this.darkMode = localStorage.getItem("theme") === "dark" || false;

        makeAutoObservable(this);
    }

    setToken = (email: string, token: string) => {
        this.token = token;
        this.authenticated = true;
        this.setEmail(email);
    }

    setEmail = (email: string) => {
        this.userEmail = email;
    }

    logout = () => {
        this.token = "";
        this.authenticated = false;
        this.userEmail = "";
        localStorage.removeItem('token');
    }

    toggleTheme = () => {
        this.darkMode = !this.darkMode;
        localStorage.setItem("theme", this.darkMode ? "dark" : "light");
    }

    get theme(): "dark" | "light" {
        return this.darkMode ? "dark" : "light";
    }

    get authHeader() {
        return { "Authorization": `Bearer ${this.token}` }
    }
}