import dayjs from "dayjs";

export const formatFpcId = (value: number | undefined | null) => {
    if (value === undefined || value === null) {
        value = 0;
    }
    return "FPC-" + value.toString().padStart(4, "0");
}

export const formatMoney = (value: number | null) => {
    if (value === null) {
        return '0.00';
    }
    return value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

export const formatDate = (value: Date | null | undefined, includeTime?: boolean) => {
    if (value === null || value === undefined) {
        return "";
    }
    return dayjs(value).format(`DD-MM-YYYY${includeTime ? ", HH:mm" : ""}`);
}