import { MaterialReactTable, MRT_PaginationState, MRT_SortingState, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { EntityType } from '../../lib/util';
import { IEmployee } from '../../models/employee';
import { RootStoreContext } from '../../stores/root.store';
import FormDialog from '../Form/FormDialog.component';
import IndexTopSection from '../IndexTopSection.component';
import TableActions from '../Table/TableActions.component';
import EmployeeForm from './EmployeeForm';

const EmployeesIndex = () => {
    const { apiStore } = useContext(RootStoreContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isReloading, setIsReloading] = useState(false);
    const [employees, setEmployees] = useState<IEmployee[]>([]);

    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 25
    });
    const [rowCount, setRowCount] = useState(0);

    const columns = useMemo<MRT_ColumnDef<IEmployee>[]>(() => [
        {
            header: 'ID',
            accessorKey: 'formatted_fpc_id'
        },
        {
            header: "Full Name",
            accessorKey: "full_name"
        },
        {
            header: "Join Date",
            accessorKey: 'formatted_join_date',
        },
        {
            header: "Last Update",
            accessorKey: 'formatted_updated_at',
        }
    ], []);

    const loadData = async () => {
        try {
            if (!employees.length) {
                setIsLoading(true);
            }
            else {
                setIsReloading(true);
            }
            const response = await apiStore.getEmployees(
                pagination.pageIndex,
                pagination.pageSize,
                globalFilter,
                JSON.stringify(sorting ?? [])
            );
            setEmployees(response.data);
            setRowCount(response.total_count);
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsLoading(false);
            setIsReloading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [pagination.pageIndex, pagination.pageSize, globalFilter, sorting]);

    const table = useMaterialReactTable({
        columns,
        data: employees,
        enableRowActions: true,
        positionActionsColumn: "last",
        autoResetPageIndex: false,
        enableFullScreenToggle: false,
        renderRowActions: ({ row }) => (
            <TableActions
                entityType={EntityType.Employee}
                id={row.original.id}
                onDelete={loadData}
                onDuplicate={loadData}
            />
        ),
        enableColumnFilters: false,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        onGlobalFilterChange: setGlobalFilter,
        rowCount,
        onPaginationChange: setPagination,
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100],
        },
        onSortingChange: setSorting,
        state: {
            isLoading,
            showProgressBars: isReloading,
            pagination,
            globalFilter,
            sorting
        }
    })

    return (
        <>
            <IndexTopSection />
            <MaterialReactTable table={table} />
            <FormDialog
                content={EmployeeForm}
                loadData={loadData}
                maxWidth='md'
            />
        </>
    )
}

export default EmployeesIndex;
