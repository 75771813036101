import DownloadIcon from '@mui/icons-material/Download';
import {
    Box,
    Button, Checkbox, CircularProgress,
    Divider,
    Grid,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Util from "../../lib/util";
import { IOrderClientReport, IOrderProductReport } from "../../models/orderReport";
import { RootStoreContext } from "../../stores/root.store";
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { IOrder } from '../../models/order';
import { useToast } from '../../lib/customHooks';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`orders-reports-tabpanel-${index}`}
            aria-labelledby={`orders-reports-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0, paddingTop: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `orders-reports-tab-${index}`,
        'aria-controls': `orders-reports-tabpanel-${index}`,
    };
}

const OrdersReportsContent = () => {
    const { apiStore } = useContext(RootStoreContext);
    const { error } = useToast();
    const [tabValue, setTabValue] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().endOf('month'));
    const [clients, setClients] = useState<IOrderClientReport[]>([]);
    const [products, setProducts] = useState<IOrderProductReport[]>([]);

    const [monthlyDate, setMonthlyDate] = useState<Dayjs | null>(dayjs());
    const [monthlyOrders, setMonthlyOrders] = useState<IOrder[]>([]);
    const [selectedMonthlyOrders, setSelectedMonthlyOrders] = useState<number[]>([]);

    const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }, []);

    const handleDownloadOrdersByClient = useCallback(async () => {
        try {
            if (startDate === null || endDate === null) {
                return;
            }

            await apiStore.downloadOrdersByClientReportExcel(startDate, endDate);
        } catch (e: any) {
            error(e);
        }
    }, [startDate, endDate]);

    const handleDownloadOrdersByProduct = useCallback(async () => {
        try {
            if (startDate === null || endDate === null) {
                return;
            }
            await apiStore.downloadOrdersByProductReportExcel(startDate, endDate);
        } catch (e: any) {
            error(e);
        }
    }, [startDate, endDate]);

    const handleToggleAllMonthlyOrdersSelection = useCallback(() => {
        if (selectedMonthlyOrders.length < monthlyOrders.length) {
            setSelectedMonthlyOrders(monthlyOrders.map(o => o.id));
        } else {
            setSelectedMonthlyOrders([]);
        }
    }, [selectedMonthlyOrders, monthlyOrders]);

    const handleToggleMonthlyOrderSelection = useCallback((event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const { checked } = event.target;
        if (checked) {
            setSelectedMonthlyOrders([...selectedMonthlyOrders, id]);
        }
        else {
            setSelectedMonthlyOrders(prev => prev.filter(o => o !== id));
        }
    }, [selectedMonthlyOrders]);

    const handleExportMonthlyOrders = useCallback(async () => {
        try {
            await apiStore.downloadOrdersByMonthExcel(selectedMonthlyOrders, monthlyDate!.toDate());
        } catch (e: any) {
            error(e);
        }
    }, [selectedMonthlyOrders, monthlyDate]);

    const loadData = async () => {
        try {
            setIsLoading(true);
            if (tabValue === 0) {
                if (startDate === null || endDate === null) {
                    return;
                }
                setClients(await apiStore.getOrdersReportByClient(startDate, endDate));
            }
            else if (tabValue === 1) {
                if (startDate === null || endDate === null) {
                    return;
                }
                setProducts(await apiStore.getOrdersByProductReport(startDate, endDate));
            } else if (tabValue === 2) {
                setMonthlyOrders(await apiStore.getOrdersByMonth(monthlyDate!));
                setSelectedMonthlyOrders([]);
            }
        }
        catch (e: any) {
            error(e);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [tabValue, monthlyDate, startDate, endDate]);

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab value={0} label={"Per Client"} {...a11yProps(0)} />
                <Tab value={1} label={"Per Product"} {...a11yProps(1)} />
                <Tab value={2} label={"Monthly"} {...a11yProps(2)} />
            </Tabs>
            {/* Per Client */}
            <TabPanel index={0} value={tabValue}>
                <Grid container spacing={2} direction={"column"}>
                    <Grid item container spacing={1} direction={"column"}>
                        <Grid item container spacing={3} alignItems={"center"}>
                            <Grid item>
                                <DatePicker
                                    label={"Start Date"}
                                    value={startDate}
                                    onAccept={setStartDate}
                                    views={['year', 'month', 'day']}
                                />
                            </Grid>
                            <Grid item>
                                <DatePicker
                                    label={"End Date"}
                                    value={endDate}
                                    onAccept={setEndDate}
                                    views={['year', 'month', 'day']}
                                />
                            </Grid>
                            {isLoading && (
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress
                                        variant={"indeterminate"}
                                        color={"primary"}
                                        size={22}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table>
                                <TableHead
                                    sx={{
                                        backgroundColor: theme => theme.palette.action.hover
                                    }}>
                                    <TableRow>
                                        <TableCell align={"center"} colSpan={4}>Client</TableCell>
                                        <TableCell align={"right"}>
                                            <Button
                                                variant={"contained"}
                                                color={"success"}
                                                startIcon={<DownloadIcon />}
                                                onClick={handleDownloadOrdersByClient}
                                            >
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Orders</TableCell>
                                        <TableCell>Color</TableCell>
                                        <TableCell>Programmed (Kgs.)</TableCell>
                                        <TableCell>Total (Kgs.)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clients.map((client, clientIndex) => (
                                        <TableRow key={`orders-client-${clientIndex}`}>
                                            <TableCell>{client.client_name}</TableCell>
                                            <TableCell>{client.orders_count}</TableCell>
                                            <TableCell>{client.reference_paper_color_display}</TableCell>
                                            <TableCell>{Util.formatNumberWithCommas(client.programmed_kilograms)}</TableCell>
                                            <TableCell>{Util.formatNumberWithCommas(client.total_kilograms)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </TabPanel>
            {/* Per Product */}
            <TabPanel index={1} value={tabValue}>
                <Grid container spacing={2} direction={"column"}>
                    <Grid item container spacing={1} direction={"column"}>
                        <Grid item container spacing={3} alignItems={"center"}>
                            <Grid item>
                                <DatePicker
                                    label={"Start Date"}
                                    value={startDate}
                                    onAccept={setStartDate}
                                    views={['year', 'month', 'day']}
                                />
                            </Grid>
                            <Grid item>
                                <DatePicker
                                    label={"End Date"}
                                    value={endDate}
                                    onAccept={setEndDate}
                                    views={['year', 'month', 'day']}
                                />
                            </Grid>
                            {isLoading && (
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress
                                        variant={"indeterminate"}
                                        color={"primary"}
                                        size={22}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table>
                                <TableHead
                                    sx={{
                                        backgroundColor: theme => theme.palette.action.hover
                                    }}>
                                    <TableRow>
                                        <TableCell align={"center"} colSpan={4}>Product</TableCell>
                                        <TableCell align={"right"}>
                                            <Button
                                                variant={"contained"}
                                                color={"success"}
                                                startIcon={<DownloadIcon />}
                                                onClick={handleDownloadOrdersByProduct}
                                            >
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Orders</TableCell>
                                        <TableCell>Color</TableCell>
                                        <TableCell>Programmed (Kgs.)</TableCell>
                                        <TableCell>Total (Kgs.)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((product, productIndex) => (
                                        <TableRow key={`orders-client-${productIndex}`}>
                                            <TableCell>{product.reference_name}</TableCell>
                                            <TableCell>{product.orders_count}</TableCell>
                                            <TableCell>{product.reference_paper_color_display}</TableCell>
                                            <TableCell>{Util.formatNumberWithCommas(product.programmed_kilograms)}</TableCell>
                                            <TableCell>{Util.formatNumberWithCommas(product.total_kilograms)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </TabPanel>
            {/* Monthly */}
            <TabPanel index={2} value={tabValue}>
                <Grid container spacing={3} direction={"column"}>
                    <Grid item container spacing={1} direction={"column"}>
                        <Grid item container spacing={3} alignItems={"center"}>
                            <Grid item>
                                <DatePicker
                                    views={['month', 'year']}
                                    label={"Month & Year"}
                                    value={monthlyDate}
                                    onAccept={setMonthlyDate}
                                />
                            </Grid>
                            {selectedMonthlyOrders.length > 0 && (
                                <Grid item>
                                    <Button
                                        variant={"contained"}
                                        color={"success"}
                                        onClick={handleExportMonthlyOrders}
                                        disabled={isLoading}
                                    >
                                        Export {selectedMonthlyOrders.length} / {monthlyOrders.length} Orders
                                    </Button>
                                </Grid>
                            )}
                            {isLoading && (
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CircularProgress
                                        variant={"indeterminate"}
                                        color={"primary"}
                                        size={22}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedMonthlyOrders.length === monthlyOrders.length && selectedMonthlyOrders.length > 0}
                                                indeterminate={selectedMonthlyOrders.length < monthlyOrders.length && selectedMonthlyOrders.length > 0}
                                                onChange={handleToggleAllMonthlyOrdersSelection}
                                            />
                                        </TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Project</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Color</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {monthlyOrders.map(order => (
                                        <TableRow key={`order-${order.order_code}`}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedMonthlyOrders.includes(order.id)}
                                                    onChange={event => handleToggleMonthlyOrderSelection(event, order.id)}
                                                />
                                            </TableCell>
                                            <TableCell>{order.order_code}</TableCell>
                                            <TableCell>{order.client_name}</TableCell>
                                            <TableCell>{order.project_name}</TableCell>
                                            <TableCell>{order.reference_name}</TableCell>
                                            <TableCell>{Util.getOrderReferencePaperColor(order.reference_paper_color)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </TabPanel>
        </Box>
    )
}

export default OrdersReportsContent;
