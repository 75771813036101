import {useSnackbar, VariantType} from "notistack";

const useToast = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const showToast = (msg: string, variant: VariantType) => {
        const key = enqueueSnackbar(msg, {
            variant,
            style: {
                cursor: "pointer",
                whiteSpace: "pre-line"
            },
            SnackbarProps: {
                onClick: () => {
                    closeSnackbar(key);
                }
            }
        });
    }

    const success = (msg: string) => showToast(msg, "success");
    const info = (msg: string) => showToast(msg, "info");
    const error = (msg: string) => showToast(msg, "error");

    return {success, info, error};
}

export {
    useToast
}