import { Divider, Grid, Typography } from "@mui/material"
import React from "react"

interface Props {
    title: string;
}

const FormSectionTitle = (props: Props) => {
    return (
        <Grid container item spacing={1} direction={"column"}>
            <Grid item>
                <Typography variant={"h6"}>
                    {props.title}
                </Typography>
            </Grid>
            <Grid item>
                <Divider />
            </Grid>
        </Grid>
    )
}

export default FormSectionTitle;
