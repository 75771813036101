import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import React from "react";

interface Props {
    edit: boolean;
    title: string;
    loading: boolean;
    onClose: () => void;
}

const FormDialogTitle = (props: Props) => {
    return (
        <Grid container spacing={2} alignItems={"center"}>
            <Grid item>
                <Typography variant="h6">
                    {`${props.edit ? "Edit" : "New"} ${props.title}`}
                </Typography>
            </Grid>
            {props.loading && (
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress color="info" size={20} />
                </Grid>
            )}
            <Grid item sx={{ ml: 'auto' }}>
                <IconButton
                    onClick={props.onClose}
                >
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default FormDialogTitle;
