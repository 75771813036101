import dayjs from "dayjs";
import { IEmployee } from "../models/employee";
import { IGeneralInfo } from "../models/generalInfo";
import { IReport, IReportEntryDay } from "../models/report";
import { isSameDate } from "./time";

export enum EntityType {
    None, Employee, GeneralInfo, Report,
    ManufacturingOrder, Payroll
}

export const getEntityPath = (entityType: EntityType): string => {
    let path = "";
    switch (entityType) {
        case EntityType.Employee:
            path = "employees"
            break;
        case EntityType.GeneralInfo:
            path = "general_infos";
            break;
        case EntityType.Report:
            path = "reports";
            break;
        case EntityType.ManufacturingOrder:
            path = "orders";
            break;
        case EntityType.Payroll:
            path = "payrolls";
            break;
    }
    return path;
}

export const getEmployeeFullName = (employee: IEmployee | null): string => {
    if (employee !== null) {
        return employee.name + " " + employee.last_name;
    }
    return "N/A";
}

export const getDayWorkedHours = (day: IReportEntryDay): number => {
    let total = 0;
    const dayDate = dayjs(day.entry_date);
    const dayDay = dayDate.day();

    if (dayDay === 0 || day.is_holiday) {
        return 0;
    }

    total = calculateWorkedHoursPerDay(day);

    if (dayDay === 6) {
        const doubleHours = getDayDoubleHours(day);
        if (total >= doubleHours) {
            total -= doubleHours;
        }
    }

    return total;
}

const calculateWorkedHoursPerDay = (day: IReportEntryDay): number => {
    let total = 0;

    for (let i = 0; i < day.report_entry_day_events.length; i++) {
        const event = day.report_entry_day_events[i];
        const startTime = dayjs(event.start_time).set('seconds', 0);
        const endTime = dayjs(event.end_time).set('seconds', 0);
        if (endTime > startTime) {
            total += endTime.diff(startTime, 'hours', true);
        }
    }

    return total;
}

export const getDayExtraHours = (day: IReportEntryDay): number => {
    let total = 0;
    const dayDate = dayjs(day.entry_date);
    const dayDay = dayDate.day();

    // Monday to Friday
    if (dayDay > 0 && dayDay < 6 && !day.is_holiday) {
        const dayHours = getDayWorkedHours(day);
        if (dayHours >= 9) {
            total = dayHours - 9;
        }
        const nightHours = getDayNightHours(day);
        total -= nightHours;
    }

    return total;
}

export const getDayDoubleHours = (day: IReportEntryDay): number => {
    let total = 0;
    const dayDate = dayjs(day.entry_date);
    const dayDay = dayDate.day();

    // Sunday
    if (dayDay === 0 || day.is_holiday) {
        total = calculateWorkedHoursPerDay(day);
    }
    // Saturday
    else if (dayDay === 6) {
        const midday = dayjs(day.entry_date).set('hour', 12).set('minute', 0).set('second', 0);

        for (let i = 0; i < day.report_entry_day_events.length; i++) {
            const event = day.report_entry_day_events[i];
            const startDate = dayjs(event.start_time).set('second', 0);
            const endDate = dayjs(event.end_time).set('second', 0);

            // If we start in the morning and finish past midday
            if (startDate.isBefore(midday) && endDate.isAfter(midday)) {
                total += endDate.diff(midday, 'hours', true);
            }
            // If we work past midday
            else if (startDate.isAfter(midday) && endDate.isAfter(midday)) {
                total += endDate.diff(startDate, 'hours', true);
            }
        }
    }

    return total;
}

export const getDayNightHours = (day: IReportEntryDay): number => {
    let total = 0;

    for (let k = 0; k < day.report_entry_day_events.length; k++) {
        const event = day.report_entry_day_events[k];

        // 9:00 PM
        const nineNight = dayjs(event.end_time).set('hour', 21).set('minute', 0).set('second', 0).set('millisecond', 0);
        const startTime = dayjs(event.start_time);
        const endTime = dayjs(event.end_time);

        if (startTime > nineNight) {
            total += endTime.diff(startTime, "hours", true);
        } else if (endTime > nineNight) {
            total += endTime.diff(nineNight, "hours", true);
        }
    }

    if (total < 0) {
        total = 0;
    }

    return total;
}

const getFilenameFromHeaders = (headers: Headers, defaultName: string): string => {
    const header = headers.get('Content-Disposition');
    let filename = defaultName;

    if (header) {
        const parts = header.split(';');
        filename = parts[1].split('=')[1].replaceAll("\"", "");
    }

    return filename;
}

const getHoursSalary = (employeeId: number, date: Date, reports: IReport[]) => {
    const myDate = dayjs(date);
    const myReport = reports.find(r => {
        const reportDate = dayjs(r.report_date);
        return isSameDate(myDate, reportDate);
    });
    if (myReport) {
        const myEmployeeId = Number(employeeId);
        if (isNaN(myEmployeeId)) {
            return 0.0;
        }
        const myReportEntry = myReport.report_entries.find(e => e.employee_id === myEmployeeId);
        if (myReportEntry) {
            return myReportEntry.total_amount;
        }
    }

    return 0.0;
}

// Find the general info entry per capita retention and loan fee
const getTotalDeductionsFromEntries = (infos: IGeneralInfo[], infoId: number, employeeId: number): number => {
    let total = 0;
    const info = infos.find(i => i.id === infoId);
    if (info) {
        const entry = info.general_info_entries.find(e => e.employee_id === employeeId);
        if (entry) {
            const additionalRetentions = entry.general_info_entry_additional_retentions;
            // Per Capita Retention
            total += (additionalRetentions.find(r => r.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") === "retencion per capita adicional")?.amount || 0) / 4.33;
            // Loan Fee
            total += (additionalRetentions.find(r => r.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") === "cuota prestamo")?.amount || 0);
        }
    }

    return total;
}

const formatNumberWithCommas = (number: number) => {
    return new Intl.NumberFormat('en-US',
        {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(number);
}

const getOrderReferencePaperColor = (color: number) => {
    if (color === 1) {
        return "White";
    }
    else if (color === 2) {
        return "Kraft"
    }
    return "N/A";
}

export default {
    getFilenameFromHeaders,
    getHoursSalary,
    getTotalDeductionsFromEntries,
    formatNumberWithCommas,
    getOrderReferencePaperColor
}
