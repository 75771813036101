import {Box, Button} from "@mui/material";
import React, {ReactNode} from "react";

interface Props {
    title: React.ReactNode;
    onRemoveClick?: () => void;
    children: ReactNode;
}

const BorderBlock = (props: Props) => {
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    border: '1px solid black',
                    flexDirection: "column",
                    borderRadius: 2
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        backgroundColor: "#BEBEBE",
                        color: theme => theme.palette.getContrastText("#BEBEBE"),
                        p: 1,
                        borderRadius: 2,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottom: '1px solid black',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    {props.title}
                    {props.onRemoveClick && (
                        <Button onClick={props.onRemoveClick} color={"inherit"}>
                            Remove
                        </Button>
                    )}
                </Box>
                <Box sx={{p: 2}}>
                    {props.children}
                </Box>
            </Box>
        </>
    )
}

export default BorderBlock;
