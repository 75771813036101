import {observer} from "mobx-react";
import React, {useContext} from "react";
import {Navigate, Route, Routes} from "react-router";
import EmployeesIndex from "./components/Employees/EmployeesIndex";
import GeneralInfosIndex from "./components/GeneralInfo/GeneralInfosIndex";
import HomeMenu from "./components/HomeMenu/HomeMenu";
import Login from "./components/Login";
import ReportsIndex from "./components/Reports/ReportsIndex";
import HomeLayout from "./layouts/HomeLayout";
import {RootStoreContext} from "./stores/root.store";
import OrdersIndex from "./components/Orders/OrdersIndex";
import PayrollsIndex from "./components/Payrolls/PayrollsIndex";

const AppRouter = () => {
    const {authStore} = useContext(RootStoreContext);

    return (
        <Routes>
            <Route path={"/"} element={<HomeLayout/>}>
                <Route index element={<HomeMenu/>}/>
                <Route path={"/login"} element={<Login/>}/>
                {authStore.authenticated && (
                    <>
                        <Route path={"/employees"} element={<EmployeesIndex/>}/>
                        <Route path={"/generalInfo"} element={<GeneralInfosIndex/>}/>
                        <Route path={"/reports"} element={<ReportsIndex/>}/>
                        <Route path={"/orders"} element={<OrdersIndex/>}/>
                        <Route path={"/payrolls"} element={<PayrollsIndex/>}/>
                    </>
                )}
            </Route>
            <Route
                path={"*"}
                element={<Navigate to={"/"} replace/>}
            />
        </Routes>
    )
}

export default observer(AppRouter);
