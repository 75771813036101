import {Dialog} from "@mui/material";
import {observer} from "mobx-react";
import React, {useContext} from "react";
import {IFormProps} from "../../lib/form";
import {RootStoreContext} from "../../stores/root.store";
import Transition from "../Transition.component";

interface Props {
    maxWidth?: "md" | "lg";
    fullScreen?: boolean;
    content: React.FC<IFormProps>
    loadData: () => void;
}

const FormDialog = ({content: Content, ...props}: Props) => {
    const {formStore} = useContext(RootStoreContext);

    return (
        <Dialog
            onClose={formStore.handleClose}
            open={formStore.open}
            maxWidth={props.maxWidth}
            fullScreen={props.fullScreen}
            TransitionComponent={props.fullScreen ? Transition : undefined}
            fullWidth
        >
            <Content {...{
                id: formStore.id,
                onSubmit: () => formStore.onSubmit(props.loadData),
                onClose: () => formStore.handleClose(),
                baseReport: formStore.baseReport
            }} />
        </Dialog>
    )
}

export default observer(FormDialog);
