import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Button, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Control, Controller, FieldArrayWithId, useFieldArray, useWatch } from "react-hook-form";
import { formatFpcId, formatMoney } from "../../lib/format";
import { getYears } from "../../lib/time";
import { IEmployee } from "../../models/employee";
import { GeneralInfoEntryAdditionalRetention, IGeneralInfo } from "../../models/generalInfo";
import BorderBlock from "../BorderBlock.component";
import FormSectionTitle from "../Form/FormSectionTitle.component";
import FpcEmployeeTitle from "../FpcEmployeeTitle.component";

interface Props {
    control: Control<IGeneralInfo, any>;
    field: FieldArrayWithId<IGeneralInfo, "general_info_entries">;
    fieldIndex: number;
    employees: IEmployee[];
    handleRemoveEntry: (index: number) => void;
    handleEmployeeChange: (data: IEmployee | null, index: number) => void;
    handleSalaryChange: (value: number, index: number) => void;
}

const GeneralInfoEntryForm = (props: Props) => {
    const reportDateWatcher = useWatch({ control: props.control, name: 'report_date' });

    const { fields, append, remove } = useFieldArray({
        name: `general_info_entries.${props.fieldIndex}.general_info_entry_additional_retentions`,
        control: props.control
    });

    const handleAddRetention = useCallback(() => {
        append(new GeneralInfoEntryAdditionalRetention());
    }, [append]);

    const handleRemoveRetention = useCallback((index: number) => {
        remove(index);
    }, [remove]);

    return (
        <BorderBlock
            title={(
                <FpcEmployeeTitle
                    control={props.control}
                    baseName={`general_info_entries.${props.fieldIndex}`}
                />
            )}
            onRemoveClick={() => props.handleRemoveEntry(props.fieldIndex)}
        >
            <Grid container spacing={3} alignItems={"center"}>
                <Grid item xs={12} md={4}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.employee`}
                        render={({ field: { value } }) => (
                            <Autocomplete
                                options={props.employees}
                                value={value}
                                isOptionEqualToValue={(op, v) => op.id === v.id}
                                getOptionLabel={op => `${formatFpcId(op.fpc_id)} ${op.name} ${op.last_name} (${getYears(reportDateWatcher, op.join_date).toFixed(1)} years)`}
                                onChange={(_v, data) => props.handleEmployeeChange(data, props.fieldIndex)}
                                renderInput={params =>
                                    <TextField label={"Employee"} {...params} />
                                }
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.salary`}
                        render={({ field: { onChange, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Salary"}
                                type={"number"}
                                onChange={e => props.handleSalaryChange(Number(e.target.value), props.fieldIndex)}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.daily_salary`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Daily Salary"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.yearly_salary`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Yearly Salary"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.vacations`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Vacations"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.vacations_bonus`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Vacation Bonus"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.easter_royalty`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Easter Royalty"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.first_bonus`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"First Bonus"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.second_bonus`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Second Bonus"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.bonus`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Bonus"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <FormSectionTitle title={"Retentions"} />
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.afp_retention`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"AFP (2.87%)"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.sfs_retention`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"SFS (3.04%)"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.contribution_retention`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Contribution (5.91%)"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid container item spacing={1} direction={"column"}>
                    <Grid container item justifyContent={"space-between"}>
                        <Grid item>
                            <Typography variant={"h6"}>
                                Additional Retentions
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={handleAddRetention}
                            >
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    {fields.map((field, fieldIndex) => (
                        <Grid container item xs={12} md={6} key={field.id} spacing={3} alignItems={"center"}>
                            <Grid item md={3}>
                                <Controller
                                    control={props.control}
                                    name={`general_info_entries.${props.fieldIndex}.general_info_entry_additional_retentions.${fieldIndex}.amount`}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label={"Amount"}
                                            type={"number"}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={7}>
                                <Controller
                                    control={props.control}
                                    name={`general_info_entries.${props.fieldIndex}.general_info_entry_additional_retentions.${fieldIndex}.description`}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            name={"description"}
                                            label={"Description"}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md>
                                <IconButton size={"small"} onClick={() => handleRemoveRetention(fieldIndex)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <FormSectionTitle title={"Payments"} />
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.afp_payment`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"AFP (7.10%)"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.sfs_payment`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"SFS (7.09%)"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.arl_payment`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"ARL (1.10%)"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.infotep_payment`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"INFOTEP (1%)"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.contribution_payment`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"Contribution (16.29%)"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <Controller
                        control={props.control}
                        name={`general_info_entries.${props.fieldIndex}.tss_payment`}
                        render={({ field: { value, ...field } }) => (
                            <TextField
                                {...field}
                                label={"TSS (22.20%)"}
                                value={formatMoney(value)}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </BorderBlock>
    )
}

export default GeneralInfoEntryForm;
