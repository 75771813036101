import FormSectionTitle from "../../Form/FormSectionTitle.component";
import {Button, Grid, IconButton, TextField} from "@mui/material";
import {Control, Controller, useFieldArray} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React from "react";
import {IOrder, OrderReference} from "../../../models/order";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

interface Props {
    control: Control<IOrder>;
}

const OrderClientInfoForm = (props: Props) => {
    const {fields, append, remove} = useFieldArray({control: props.control, name: 'order_references'})

    return (
        <>
            <FormSectionTitle title={"Client Info."}/>
            <Grid item xs={12} md={6}>
                <Controller
                    control={props.control}
                    name={"order_code"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Code"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    name={"order_date"}
                    control={props.control}
                    render={({field}) => (
                        <DatePicker
                            {...field}
                            value={dayjs(field.value)}
                            label={"Date"}
                            slotProps={{textField: {fullWidth: true}}}
                            format={"DD-MM-YYYY"}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    control={props.control}
                    name={"client_name"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Client"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    control={props.control}
                    name={"project_name"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Project"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    control={props.control}
                    name={"location"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Location"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    control={props.control}
                    name={"city"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"City"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item container spacing={3} alignItems={"center"}>
                {fields.map((field, index) => (
                    <Grid key={field.id} item container xs={12} md={6} lg={4} spacing={1} alignItems={"center"}>
                        <Grid item xs={9}>
                            <Controller
                                control={props.control}
                                name={`order_references.${index}.reference_number`}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label={`Reference #${index + 1}`}
                                        type={"number"}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs>
                            <IconButton onClick={() => remove(index)}>
                                <CancelIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} md={6} lg={4}>
                    <Button
                        variant={"contained"}
                        color={"info"}
                        size={"small"}
                        onClick={() => append(new OrderReference())}
                        startIcon={<AddIcon/>}
                    >
                        Add Reference
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default OrderClientInfoForm;
