import { Button, Grid } from "@mui/material";
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { formatDate } from "../../lib/format";
import { IReport } from "../../models/report";
import { RootStoreContext } from "../../stores/root.store";
import FormDialog from "../Form/FormDialog.component";
import TableActions from "../Table/TableActions.component";
import ReportForm from "./ReportForm";
import UploadIcon from '@mui/icons-material/UploadFile'
import { EntityType } from "../../lib/util";
import IndexTopSection from "../IndexTopSection.component";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReportsMonthlyLogDialog from "./ReportsMonthlyLogs/ReportsMonthlyLogDialog";
import { useToast } from "../../lib/customHooks";

const ReportsIndex = () => {
    const { apiStore, formStore } = useContext(RootStoreContext);

    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState<IReport[]>([]);
    const [monthlyLogOpen, setMonthlyLogOpen] = useState(false);

    const { error } = useToast();

    const columns = useMemo<MRT_ColumnDef<IReport>[]>(() => [
        {
            header: 'Name',
            accessorKey: 'name'
        },
        {
            header: 'Start Date',
            accessorKey: 'start_date',
            Cell: ({ cell }) => formatDate(cell.getValue<Date>())
        },
        {
            header: 'End Date',
            accessorKey: 'end_date',
            Cell: ({ cell }) => formatDate(cell.getValue<Date>())
        },
        {
            accessorKey: 'report_date',
            header: 'Report Date',
            Cell: ({ cell }) => formatDate(cell.getValue<Date>())
        },
        {
            accessorKey: 'updated_at',
            header: 'Last Update',
            Cell: ({ cell }) => formatDate(cell.getValue<Date>(), true)
        }
    ], []);

    const uploadRef = useRef<HTMLInputElement | null>(null);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target?.files && event.target.files.length > 0) {
            const report = event.target.files[0]
            if (report) {
                const formData = new FormData()
                formData.append('file', report);
                try {
                    const baseReport = await apiStore.parseTimeCard(formData);
                    formStore.handleOpenWithBaseReport(baseReport);
                } catch (e: any) {
                    console.log(e);
                    error(`${e.message}\nMake sure you're using the correct format!`);
                }
                finally {
                    if (uploadRef.current) {
                        uploadRef.current.value = "";
                    }
                }
            }
        }
    }

    const handleOpenMonthlyLog = () => {
        setMonthlyLogOpen(true);
    }

    const handleMonthlyLogClose = () => {
        setMonthlyLogOpen(false);
    }

    const handleDelete = (id: number) => {
        setReports(prev => prev.filter(r => r.id !== id));
    }

    const loadData = async () => {
        try {
            setLoading(true);
            setReports(await apiStore.getReports());
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    const table = useMaterialReactTable({
        columns,
        data: reports,
        enableRowActions: true,
        positionActionsColumn: "last",
        autoResetPageIndex: false,
        enableFullScreenToggle: false,
        renderRowActions: ({ row }) => (
            <TableActions
                entityType={EntityType.Report}
                id={row.original.id}
                onDelete={() => handleDelete(row.original.id)}
                onDuplicate={loadData}
            />
        ),
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 25
            }
        },
        state: {
            isLoading: loading
        },
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100],
        }
    })

    return (
        <>
            <IndexTopSection topButtons={(
                <>
                    <Grid item sx={{ ml: 'auto' }}>
                        <Button
                            variant={"contained"}
                            component={"label"}
                            color={"secondary"}
                            startIcon={<UploadIcon />}
                        >
                            Time Card Report
                            <input ref={uploadRef} hidden accept={".csv"} type={"file"}
                                onChange={handleFileUpload} />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="info"
                            startIcon={<CalendarMonthIcon />}
                            onClick={handleOpenMonthlyLog}
                        >
                            Monthly Log
                        </Button>
                    </Grid>
                </>
            )}
            />
            <MaterialReactTable table={table} />
            <FormDialog
                content={ReportForm}
                loadData={loadData}
                fullScreen
            />
            {/* Monthly Log */}
            <ReportsMonthlyLogDialog
                open={monthlyLogOpen}
                onClose={handleMonthlyLogClose}
            />
        </>
    )
}

export default ReportsIndex;
