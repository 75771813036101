import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";
import ReportsMonthlyLogContent from "./ReportsMonthlyLogContent";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    open: boolean;
    onClose: () => void;
}

const ReportsMonthlyLogDialog = (props: Props) => {
    return (
        <Dialog
            open={props.open}
            maxWidth={"lg"}
            fullWidth
        >
            <DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography component={"h1"} variant={"h6"}>
                        Reports | Monthly Log
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <ReportsMonthlyLogContent />
            </DialogContent>
        </Dialog>
    )
}

export default ReportsMonthlyLogDialog;
