import {Button} from "@mui/material"
import React from "react";
import {useNavigate} from "react-router";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import AddIcon from '@mui/icons-material/Add';

type Props = {
    onClick: () => void;
}
export const BackButton = () => {
    const navigate = useNavigate();
    const onClick = () => navigate(-1);

    return (
        <Button
            variant={"text"}
            color={"inherit"}
            onClick={onClick}
            startIcon={<ArrowLeftIcon/>}
        >
            Back
        </Button>
    )
}
export const NewButton = (props: Props) => {
    return (
        <Button
            variant={"contained"}
            onClick={props.onClick}
            startIcon={<AddIcon/>}
        >
            New
        </Button>
    )
}