import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Avatar, Box, Button, Container, TextField, Typography} from "@mui/material";
import {observer} from 'mobx-react';
import React, {useContext, useState} from "react";
import {Navigate} from 'react-router';
import {RootStoreContext} from '../stores/root.store';
import {useToast} from "../lib/customHooks";

const Login = () => {
    const {authStore, apiStore} = useContext(RootStoreContext);
    const [submitting, setSubmitting] = useState(false);
    const {error, success} = useToast();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setSubmitting(true);
            const data = new FormData(event.currentTarget);
            const email = data.get('email')?.toString();
            const password = data.get('password')?.toString();
            if (email && password) {
                await apiStore.login(email, password);
                success(`Welcome back, ${email}!`);
            }
        } catch (e: any) {
            error(e.message);
        } finally {
            setSubmitting(false);
        }
    }

    if (authStore.authenticated) {
        return <Navigate to={"/"} replace/>
    }

    return (
        <Container component={"main"} maxWidth={"xs"}>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Avatar sx={{m: 1, bgColor: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component={"h1"} variant={"h5"}>
                    Sign In
                </Typography>
                <Box component={"form"} onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin={"normal"}
                        id={"email"}
                        label={"Email Address"}
                        name={"email"}
                        autoComplete={"email"}
                        autoFocus
                        required
                        fullWidth
                        disabled={submitting}
                    />
                    <TextField
                        margin={"normal"}
                        id={"password"}
                        label={"Password"}
                        name={"password"}
                        autoComplete={"password"}
                        type={"password"}
                        required
                        fullWidth
                        disabled={submitting}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        disabled={submitting}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}

export default observer(Login);
