import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import { formatMoney } from "../../lib/format";
import Util from "../../lib/util";
import { IPayroll } from "../../models/payroll";
import { IReport } from "../../models/report";

interface Props {
    control: Control<IPayroll>;
    entryIndex: number;
    fieldIndex: number;
    reports: IReport[];
}

const PayrollEntryDaySalaryForm = (props: Props) => {
    const employeeIdWatcher = useWatch({
        control: props.control,
        name: `payroll_entries.${props.entryIndex}.employee_id`
    })
    const dateWatcher = useWatch({
        control: props.control,
        name: `payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.entry_date`
    });

    const isCustomWatcher = useWatch({
        control: props.control,
        name: `payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.is_custom`
    });

    const [hoursSalary, setHoursSalary] = useState(0);

    useEffect(() => {
        setHoursSalary(Util.getHoursSalary(employeeIdWatcher, dateWatcher, props.reports));
    }, [props.reports, dateWatcher, employeeIdWatcher]);

    return (
        <>
            <Grid item xs={12} md={6}>
                <TextField
                    value={formatMoney(hoursSalary)}
                    label={"Extra Hours"}
                    InputProps={{ readOnly: true }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    control={props.control}
                    name={`payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.total_amount`}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Total"}
                            type={"number"}
                            InputProps={{ readOnly: !isCustomWatcher }}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    control={props.control}
                    name={`payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.additional_value`}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Additional"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    control={props.control}
                    name={`payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.is_custom`}
                    render={({ field: { value, ...field } }) => (
                        <FormControlLabel
                            {...field}
                            label={"Custom"}
                            control={<Checkbox checked={value} />}
                        />
                    )}
                />
            </Grid>
        </>
    )
}

export default PayrollEntryDaySalaryForm;
