import {Typography} from "@mui/material";
import React from "react";
import {Control, useWatch} from "react-hook-form";
import {formatFpcId} from "../lib/format";
import {IEmployee} from "../models/employee";

interface Props {
    control: Control<any>;
    baseName: string;
}

const FpcEmployeeTitle = (props: Props) => {
    const employeeWatcher: IEmployee | null = useWatch({control: props.control, name: `${props.baseName}.employee`});

    return (
        <Typography>
            <strong>{formatFpcId(employeeWatcher?.fpc_id)}</strong>
        </Typography>
    )
}

export default FpcEmployeeTitle;
