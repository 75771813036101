import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { IFormProps } from "../../lib/form";
import { Employee, IEmployee } from "../../models/employee";
import { RootStoreContext } from "../../stores/root.store";
import FormSectionTitle from "../Form/FormSectionTitle.component";
import FormDialogTitle from "../FormDialogTitle.component";

const EmployeeForm = (props: IFormProps) => {
    const { apiStore } = useContext(RootStoreContext);

    const { control, handleSubmit, reset } = useForm<IEmployee>({
        defaultValues: new Employee()
    });

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const onSubmit: SubmitHandler<IEmployee> = async (values) => {
        try {
            setSubmitting(true);
            await apiStore.submitEmployee(values, props.id);
            props.onSubmit();
        } catch (e) {
            console.log(e);
        } finally {
            setSubmitting(false);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (props.id > 0) {
                    reset(await apiStore.getEmployee(props.id));
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [props.id]);

    return (
        <>
            <DialogTitle>
                <FormDialogTitle
                    edit={props.id > 0}
                    title={"Employee"}
                    loading={loading}
                    onClose={props.onClose}
                />
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    <FormSectionTitle title={"General"} />
                    <Grid item xs={12} sm={6} md={4}>
                        <Controller
                            control={control}
                            name={"fpc_id"}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={"ID"}
                                    type={"number"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Controller
                            control={control}
                            name={"name"}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={"Name"}
                                    placeholder={"John"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Controller
                            control={control}
                            name={"last_name"}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={"Last Name"}
                                    placeholder={"Doe"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Controller
                            name={"join_date"}
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    value={dayjs(field.value)}
                                    label={"Join Date"}
                                    slotProps={{ textField: { fullWidth: true } }}
                                    format={"DD-MM-YYYY"}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Controller
                            name={"group"}
                            control={control}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="group-select-label">Group</InputLabel>
                                    <Select
                                        {...field}
                                        label={"Group"}
                                        labelId={"group-select-label"}
                                        id={"group-select"}
                                    >
                                        <MenuItem value={0}>None</MenuItem>
                                        <MenuItem value={1}>Administrative</MenuItem>
                                        <MenuItem value={2}>Operations</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                        {/*  */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Controller
                            control={control}
                            name={"department"}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="department-select-label">Department</InputLabel>
                                    <Select
                                        {...field}
                                        label={"Department"}
                                        labelId={"department-select-label"}
                                        id={"department-select"}
                                    >
                                        <MenuItem value={0}>None</MenuItem>
                                        <MenuItem value={1}>Administration</MenuItem>
                                        <MenuItem value={2}>Production</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Controller
                            control={control}
                            name={"position"}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="position-select-label">Position</InputLabel>
                                    <Select
                                        {...field}
                                        label={"Position"}
                                        labelId={"position-select-label"}
                                        id={"position-select"}
                                    >
                                        <MenuItem value={0}>None</MenuItem>
                                        <MenuItem value={1}>General Manager</MenuItem>
                                        <MenuItem value={2}>Administrative Assistant</MenuItem>
                                        <MenuItem value={9}>Receptionist</MenuItem>
                                        <MenuItem value={3}>Operations Supervisor</MenuItem>
                                        <MenuItem value={4}>Extruder Operator</MenuItem>
                                        <MenuItem value={5}>Flexo Operator</MenuItem>
                                        <MenuItem value={6}>Cut Operator</MenuItem>
                                        <MenuItem value={8}>Operator</MenuItem>
                                        <MenuItem value={7}>Concierge</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} disabled={submitting}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} disabled={submitting}>
                    Submit
                </Button>
            </DialogActions>
        </>
    )
}

export default EmployeeForm;
