import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import {observer} from "mobx-react";
import {useContext} from "react";
import React from "react";
import {RootStoreContext} from "../stores/root.store";
import Transition from "./Transition.component";

const DeleteConfirmDialog = () => {
    const {duplicateStore} = useContext(RootStoreContext);

    return (
        <Dialog
            open={duplicateStore.open}
            onClose={duplicateStore.closeDialog}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                Duplicate Confirmation
            </DialogTitle>
            <DialogContent dividers>
                Do you really want to duplicate this entry?
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} onClick={duplicateStore.closeDialog}>
                    No
                </Button>
                <Button color={"secondary"} onClick={duplicateStore.confirmDuplicate}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default observer(DeleteConfirmDialog);
