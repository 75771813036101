import {Autocomplete, Button, Divider, Grid, TextField, Typography} from "@mui/material";
import dayjs from "dayjs";
import React, {useEffect} from "react";
import {Control, Controller, UseFormGetValues, UseFormSetValue, useFieldArray, useWatch} from "react-hook-form";
import {formatFpcId} from "../../lib/format";
import {IEmployee} from "../../models/employee";
import {IReport, ReportEntry, ReportEntryDay} from "../../models/report";
import BorderBlock from "../BorderBlock.component";
import ReportEntryDaysForm from "./ReportEntryDaysForm";
import FpcEmployeeTitle from "../FpcEmployeeTitle.component";
import AddIcon from "@mui/icons-material/Add";

interface Props {
    control: Control<IReport, any>;
    employees: IEmployee[];
    setValue: UseFormSetValue<IReport>;
    getValues: UseFormGetValues<IReport>;
}

const ReportEntriesForm = (props: Props) => {
    const {fields: entryFields, append: entryAppend, remove: entryRemove} = useFieldArray({
        name: "report_entries",
        control: props.control
    });
    const generalInfoWatcher = useWatch({control: props.control, name: "general_info"});

    const handleAddEntry = () => {
        const values = props.getValues();
        const newEntry = new ReportEntry();

        const daysInBetween = dayjs(values.end_date).diff(values.start_date, 'day');

        for (let i = 0; i <= daysInBetween; i++) {
            const myDate = dayjs(values.start_date).add(i, 'day');
            newEntry.report_entry_days.push(new ReportEntryDay({entry_date: myDate.toDate()}));
        }

        entryAppend(newEntry);
    }

    const handleRemoveEntry = (index: number) => {
        entryRemove(index);
    }

    const handleEmployeeChange = (value: IEmployee | null, index: number) => {
        props.setValue(`report_entries.${index}.employee`, value);
        props.setValue(`report_entries.${index}.employee_id`, value?.id || 0);
    }

    useEffect(() => {
        const values = props.getValues();
        for (let i = 0; i < values.report_entries.length; i++) {
            let dailySalary = 0.00;
            if (generalInfoWatcher) {
                const entry = generalInfoWatcher.general_info_entries.find(e => e.employee_id === values.report_entries[i].employee_id);
                if (entry) {
                    dailySalary = entry.daily_salary;
                }
            }
            props.setValue(`report_entries.${i}.daily_salary`, dailySalary);
        }
    }, [generalInfoWatcher]);

    return (
        <Grid container item spacing={3}>
            <Grid container item spacing={1} direction={"column"}>
                <Grid container item justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant={"h6"}>
                            Entries
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            onClick={handleAddEntry}
                            startIcon={<AddIcon/>}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider/>
                </Grid>
            </Grid>
            {entryFields.map((field, fieldIndex) => (
                <Grid item xs={12} key={field.id}>
                    <BorderBlock
                        title={(
                            <FpcEmployeeTitle
                                control={props.control}
                                baseName={`report_entries.${fieldIndex}`}
                            />
                        )}
                        onRemoveClick={() => handleRemoveEntry(fieldIndex)}
                    >
                        <Grid container spacing={3} alignItems={"center"}>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    control={props.control}
                                    name={`report_entries.${fieldIndex}.employee`}
                                    render={({field: {value}}) => (
                                        <Autocomplete
                                            options={props.employees}
                                            value={value}
                                            isOptionEqualToValue={(op, v) => op.id === v.id}
                                            getOptionLabel={op => `${formatFpcId(op.fpc_id)} ${op.name} ${op.last_name}`}
                                            onChange={(_, v) => handleEmployeeChange(v, fieldIndex)}
                                            renderInput={params =>
                                                <TextField label={"Employee"} {...params} />
                                            }
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <ReportEntryDaysForm
                                control={props.control}
                                fieldIndex={fieldIndex}
                                getValues={props.getValues}
                                setValue={props.setValue}
                            />
                        </Grid>
                    </BorderBlock>
                </Grid>
            ))}
        </Grid>
    )
}

export default ReportEntriesForm;
