import { Control, Controller, UseFormSetValue, useWatch } from "react-hook-form";
import { IPayroll } from "../../models/payroll";
import { IReport } from "../../models/report";
import React, { useEffect } from "react";
import Util from "../../lib/util";
import { formatMoney } from "../../lib/format";
import { IGeneralInfo } from "../../models/generalInfo";
import { Typography } from "@mui/material";

interface Props {
    control: Control<IPayroll>;
    setValue: UseFormSetValue<IPayroll>;
    entryIndex: number;
    fieldIndex: number;
    reports: IReport[];
    generalInfos: IGeneralInfo[];
}

const PayrollEntryDayTitleForm = (props: Props) => {
    const infoWatcher = useWatch({ control: props.control, name: 'general_info' });

    const totalDeductionsWatcher = useWatch({ control: props.control, name: `payroll_entries.${props.entryIndex}.total_deductions` });

    const dateWatcher = useWatch({
        control: props.control,
        name: `payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.entry_date`
    });
    const otherDeductionsWatcher = useWatch({
        control: props.control,
        name: `payroll_entries.${props.entryIndex}.other_deductions`
    });

    const employeeIdWatcher = useWatch({
        control: props.control,
        name: `payroll_entries.${props.entryIndex}.employee_id`
    });

    const additionalWatcher = useWatch({
        control: props.control,
        name: `payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.additional_value`
    });

    const isCustomWatcher = useWatch({
        control: props.control,
        name: `payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.is_custom`
    });

    useEffect(() => {
        if (isCustomWatcher) {
            return;
        }

        const employeeId = Number(employeeIdWatcher);
        if (infoWatcher !== null && !isNaN(employeeId)) {
            const entry = infoWatcher.general_info_entries.find(e => e.employee_id === employeeId);
            if (entry !== undefined) {
                const salary = entry.salary / 4.33;
                const afp = salary * 0.0287;
                const ars = salary * 0.0304;
                const hoursSalary = Util.getHoursSalary(employeeId, dateWatcher, props.reports);
                const additional = Number(additionalWatcher);
                let totalDeductions = Number(otherDeductionsWatcher) + ars + afp;
                totalDeductions += Util.getTotalDeductionsFromEntries(props.generalInfos, infoWatcher?.id || 0, employeeIdWatcher || 0);
                const total = salary - totalDeductions + additional + hoursSalary;
                props.setValue(`payroll_entries.${props.entryIndex}.total_deductions`, totalDeductions);
                props.setValue(
                    `payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.total_amount`,
                    Math.round((total + Number.EPSILON) * 100) / 100
                );
            }
        }
    }, [infoWatcher, employeeIdWatcher, dateWatcher, props.reports, additionalWatcher, isCustomWatcher, totalDeductionsWatcher]);

    return (
        <Typography>
            <strong>Week #{props.fieldIndex + 1}</strong>
        </Typography>
        // <Controller
        //     control={props.control}
        //     name={`payroll_entries.${props.entryIndex}.payroll_entry_days.${props.fieldIndex}.total_amount`}
        //     render={({ field }) => (
        //         <span>
        //             <strong>{`$ ${formatMoney(field.value)}`}</strong>
        //         </span>
        //     )}
        // />
    )
}

export default PayrollEntryDayTitleForm;
