import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Control, Controller, UseFormSetValue, useWatch } from "react-hook-form";
import { formatMoney } from "../../lib/format";
import { IPayroll } from "../../models/payroll";
import { IGeneralInfo, IGeneralInfoEntry } from "../../models/generalInfo";
import Util from "../../lib/util";

interface Props {
    control: Control<IPayroll>;
    fieldIndex: number;
    generalInfos: IGeneralInfo[];
    setValue: UseFormSetValue<IPayroll>;
}

const PayrollEntrySalaryForm = (props: Props) => {
    const infoIdWatcher = useWatch({ control: props.control, name: `general_info_id` });

    const employeeWatcher = useWatch({ control: props.control, name: `payroll_entries.${props.fieldIndex}.employee` });

    const otherDeductionsWatcher = useWatch({
        control: props.control,
        name: `payroll_entries.${props.fieldIndex}.other_deductions`
    });

    const [salary, setSalary] = useState(0.0);
    const [dailySalary, setDailySalary] = useState(0.0);
    const [afp, setAfp] = useState(0.0);
    const [ars, setArs] = useState(0.0);
    const [totalDeductions, setTotalDeductions] = useState(0.0);

    const setSalaryValues = (entry: IGeneralInfoEntry | null) => {
        const sl = (entry?.salary || 0) / 4.33;
        setSalary(sl);
        setDailySalary(entry?.daily_salary || 0);
        setAfp(sl * 0.0287);
        setArs(sl * 0.0304);
    }

    useEffect(() => {
        if (infoIdWatcher !== null && employeeWatcher !== null) {
            const info = props.generalInfos.find(i => i.id === infoIdWatcher);
            if (!info) return;
            const infoEntry = info.general_info_entries.find(e => e.employee_id === employeeWatcher.id);
            if (infoEntry !== undefined) {
                setSalaryValues(infoEntry);
            }
            else {
                setSalaryValues(null);
            }
        }
        else {
            setSalaryValues(null);
        }
    }, [infoIdWatcher, employeeWatcher, props.generalInfos]);

    useEffect(() => {
        let total = Number(otherDeductionsWatcher) + ars + afp;
        total += Util.getTotalDeductionsFromEntries(props.generalInfos, infoIdWatcher, employeeWatcher?.id || 0);
        props.setValue(`payroll_entries.${props.fieldIndex}.total_deductions`, total);
        setTotalDeductions(total);
    }, [otherDeductionsWatcher, employeeWatcher, ars, afp, props.generalInfos, infoIdWatcher]);

    return (
        <>
            <Grid item xs={12} md={4} lg={2}>
                <TextField
                    value={formatMoney(salary)}
                    label={"Salary"}
                    InputProps={{ readOnly: true }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <TextField
                    value={formatMoney(dailySalary)}
                    label={"Daily Salary"}
                    InputProps={{ readOnly: true }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <TextField
                    value={formatMoney(afp)}
                    label={"AFP (2.87%)"}
                    InputProps={{ readOnly: true }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <TextField
                    value={formatMoney(ars)}
                    label={"ARS (3.04%)"}
                    InputProps={{ readOnly: true }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <Controller
                    control={props.control}
                    name={`payroll_entries.${props.fieldIndex}.other_deductions`}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Other Deductions"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <TextField
                    value={totalDeductions.toFixed(2)}
                    label={"Total Deductions"}
                    InputProps={{ readOnly: true }}
                    fullWidth
                />
            </Grid>
        </>
    )
}

export default PayrollEntrySalaryForm;

