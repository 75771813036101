import {createContext} from "react";
import {ApiStore} from "./api.store";
import {AuthStore} from "./auth.store";
import {DeleteStore} from "./delete.store";
import {FormStore} from "./form.store";
import {DuplicateStore} from "./duplicate.store";

export class RootStore {
    apiStore: ApiStore;
    authStore: AuthStore;
    duplicateStore: DuplicateStore;
    deleteStore: DeleteStore;
    formStore: FormStore;

    constructor() {
        this.apiStore = new ApiStore(this);
        this.authStore = new AuthStore(this, localStorage.getItem('token') || "");
        this.duplicateStore = new DuplicateStore(this);
        this.deleteStore = new DeleteStore(this);
        this.formStore = new FormStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());