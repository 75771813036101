import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';
import {
    AppBar,
    Avatar, Box,
    Button,
    Container,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import {observer} from "mobx-react";
import React, {useCallback, useContext, useState} from "react";
import {Outlet} from "react-router";
import {Link as RouterLink} from "react-router-dom";
import Logo from "../images/logo-transparent.webp";
import {RootStoreContext} from '../stores/root.store';
import {useToast} from "../lib/customHooks";

const HomeLayout = () => {
    const {authStore} = useContext(RootStoreContext);
    const {info} = useToast();

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const theme = useTheme();

    const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    }, []);

    const handleCloseUserMenu = useCallback(() => {
        setAnchorElUser(null);
    }, []);

    const handleLogout = () => {
        authStore.logout();
        handleCloseUserMenu();
        info('Logged out');
    }

    return (
        <>
            <AppBar
                color={"inherit"}
                position={"sticky"}
                elevation={1}
                sx={{mb: 2, p: 1, borderBottom: theme => `1px solid ${theme.palette.divider}`}}
            >
                <Container maxWidth={"xl"}>
                    <Toolbar disableGutters>
                        <IconButton component={RouterLink} to={"/"} sx={{p: 0.5, m: 1}}>
                            <Avatar src={Logo} alt={"Logo"}/>
                        </IconButton>
                        <Typography
                            component={"h1"}
                            variant={"h6"}
                            color={"inherit"}
                            noWrap
                            sx={{
                                flexGrow: 1,
                                [theme.breakpoints.down('sm')]: {
                                    display: 'none'
                                }
                            }}
                        >
                            Full Packing del Caribe | ADM
                        </Typography>
                        {authStore.authenticated && (
                            <Button
                                color={"inherit"}
                                onClick={handleOpenUserMenu}
                            >
                                {authStore.userEmail}
                            </Button>
                        )}
                        <Menu
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={authStore.toggleTheme}>
                                <ListItemIcon>
                                    <DarkModeIcon/>
                                </ListItemIcon>
                                <ListItemText>Dark Mode</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <LogoutIcon/>
                                </ListItemIcon>
                                <ListItemText>Logout</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box sx={{marginLeft: 'auto', marginRight: 'auto', width: '98%'}}>
                <Outlet/>
            </Box>
        </>
    )
}

export default observer(HomeLayout);
