import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material"
import {observer} from "mobx-react";
import {useContext} from "react";
import React from "react";
import {RootStoreContext} from "../stores/root.store";
import Transition from "./Transition.component";

const DeleteConfirmDialog = () => {
    const {deleteStore} = useContext(RootStoreContext);

    return (
        <Dialog
            open={deleteStore.open}
            onClose={deleteStore.closeDialog}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                Delete Confirmation
            </DialogTitle>
            <DialogContent dividers>
                Do you really want to delete this entry?
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} onClick={deleteStore.closeDialog}>
                    No
                </Button>
                <Button color={"secondary"} onClick={deleteStore.confirmDelete}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default observer(DeleteConfirmDialog);
