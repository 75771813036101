import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { formatDate } from "../../lib/format";
import { EntityType } from "../../lib/util";
import { IGeneralInfo } from "../../models/generalInfo";
import { RootStoreContext } from "../../stores/root.store";
import FormDialog from "../Form/FormDialog.component";
import TableActions from "../Table/TableActions.component";
import GeneralInfoForm from "./GeneralInfoForm";
import IndexTopSection from "../IndexTopSection.component";

const GeneralInfosIndex = () => {
    const { apiStore } = useContext(RootStoreContext);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IGeneralInfo[]>([]);

    const columns = useMemo<MRT_ColumnDef<IGeneralInfo>[]>(() => [
        {
            header: 'Name',
            accessorKey: 'name'
        },
        {
            id: 'report_date',
            header: 'Report Date',
            accessorFn: row => formatDate(row.report_date)
        },
        {
            id: 'updated_at',
            header: 'Last Update',
            accessorFn: row => formatDate(row.updated_at, true)
        }
    ], []);

    const loadData = async () => {
        try {
            setLoading(true);
            setData(await apiStore.getGeneralInfos());
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowActions: true,
        positionActionsColumn: "last",
        autoResetPageIndex: false,
        enableFullScreenToggle: false,
        renderRowActions: ({ row }) => (
            <TableActions
                entityType={EntityType.GeneralInfo}
                id={row.original.id}
                onDelete={loadData}
                onDuplicate={loadData}
            />
        ),
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 25
            }
        },
        state: {
            isLoading: loading
        },
        muiPaginationProps: {
            rowsPerPageOptions: [25, 50, 100],
        }
    })

    return (
        <>
            <IndexTopSection />
            <MaterialReactTable table={table} />
            <FormDialog
                content={GeneralInfoForm}
                loadData={loadData}
                fullScreen
            />
        </>
    )
}

export default GeneralInfosIndex;
