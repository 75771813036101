import { Theme } from '@emotion/react';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import BadgeIcon from '@mui/icons-material/Badge';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Divider, Grid, SxProps, Typography } from "@mui/material";
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { RootStoreContext } from '../../stores/root.store';
import MenuCard from './MenuCard.component';

enum Module {
    None, Employee, GeneralInfo,
    WeeklyReport, Orders, Payroll
}

interface MenuModule {
    title: string;
    description: string;
    type: Module;
}

const HomeMenu = () => {
    const { authStore } = useContext(RootStoreContext);

    const iconStyles: SxProps<Theme> = useMemo(() => ({
        fontSize: 52
    }), []);

    const navigate = useNavigate();

    const onCardClick = (path: string) => {
        navigate(path);
    }

    const getMenuIcon = useCallback((type: Module) => {
        let Icon = null;
        if (type === Module.Employee)
            Icon = BadgeIcon;
        else if (type === Module.GeneralInfo)
            Icon = BackupTableIcon;
        else if (type === Module.WeeklyReport)
            Icon = DateRangeIcon;
        else if (type === Module.Orders)
            Icon = ListAltIcon;
        else if (type === Module.Payroll)
            Icon = CurrencyExchangeIcon;

        if (Icon !== null) {
            return <Icon sx={iconStyles} />
        }

        return null;
    }, []);

    const getModulePath = useCallback((type: Module) => {
        if (type === Module.Employee)
            return "/employees";
        else if (type === Module.GeneralInfo)
            return "/generalInfo";
        else if (type === Module.WeeklyReport)
            return "/reports";
        else if (type === Module.Orders)
            return "/orders";
        else if (type === Module.Payroll)
            return "/payrolls";
        return "";
    }, []);

    const hrModules: MenuModule[] = useMemo(() => [
        {
            title: 'Employees',
            description: 'Manage employees',
            type: Module.Employee
        },
        {
            title: 'General Info',
            description: "Manage employees payments and retentions",
            type: Module.GeneralInfo
        },
        {
            title: 'Weekly Report',
            description: 'Manage weekly reports',
            type: Module.WeeklyReport
        },
        {
            title: 'Payrolls',
            description: 'Manage employees payrolls',
            type: Module.Payroll
        }
    ], []);

    const productionModules: MenuModule[] = useMemo(() => [
        {
            title: 'Orders',
            description: "Manage manufacturing orders",
            type: Module.Orders
        }
    ], []);

    if (!authStore.authenticated) {
        return <Navigate to={"/login"} replace />
    }

    return (
        <Grid container spacing={3} sx={{ mt: 2 }}>
            {/* HHRR */}
            <Grid item container spacing={1} direction={"column"}>
                <Grid item>
                    <Typography variant={"h6"}>
                        HHRR
                    </Typography>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>
            {hrModules.map((module, moduleIndex) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={`hhrr-module-${moduleIndex}`}>
                    <MenuCard
                        title={module.title}
                        icon={getMenuIcon(module.type)}
                        description={module.description}
                        onClick={() => onCardClick(getModulePath(module.type))}
                    />
                </Grid>
            ))}
            {/* Production */}
            <Grid item container spacing={1} direction={"column"} sx={{ mt: 2 }}>
                <Grid item>
                    <Typography variant={"h6"}>
                        Production
                    </Typography>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>
            {productionModules.map((module, moduleIndex) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={`prod-module-${moduleIndex}`}>
                    <MenuCard
                        title={module.title}
                        icon={getMenuIcon(module.type)}
                        description={module.description}
                        onClick={() => onCardClick(getModulePath(module.type))}
                    />
                </Grid>
            ))}
        </Grid>
    )
}

export default observer(HomeMenu);
