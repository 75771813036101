import dayjs from "dayjs";
import { IEmployee } from "./employee";

export interface IGeneralInfoEntryAdditionalRetention {
    id: number;
    amount: number;
    description: string;
    general_info_entry_id: number;
    _destroy: boolean;
}

export class GeneralInfoEntryAdditionalRetention implements IGeneralInfoEntryAdditionalRetention {
    id: number = 0
    amount: number = 0
    description: string = "Retención adicional";
    general_info_entry_id: number = 0;
    _destroy: boolean = false;

    constructor(init?: Partial<IGeneralInfoEntryAdditionalRetention>) {
        Object.assign(this, init);
    }
}

export interface IGeneralInfoEntry {
    id: number;
    general_info_id: number;
    employee_id: number;
    employee: IEmployee | null;
    salary: number;
    daily_salary: number;
    yearly_salary: number;
    vacations: number;
    vacations_bonus: number;
    easter_royalty: number;
    first_bonus: number;
    second_bonus: number;
    bonus: number;
    afp_retention: number;
    sfs_retention: number;
    contribution_retention: number;
    general_info_entry_additional_retentions: IGeneralInfoEntryAdditionalRetention[];
    afp_payment: number;
    sfs_payment: number;
    arl_payment: number;
    infotep_payment: number;
    contribution_payment: number;
    tss_payment: number;
    _destroy: boolean;
}

export class GeneralInfoEntry implements IGeneralInfoEntry {
    id: number = 0;
    general_info_id: number = 0;
    employee_id: number = 0;
    employee: IEmployee | null = null;
    salary: number = 0;
    daily_salary: number = 0;
    yearly_salary: number = 0;
    vacations: number = 0;
    vacations_bonus: number = 0;
    easter_royalty: number = 0;
    first_bonus: number = 0;
    second_bonus: number = 0;
    bonus: number = 0;
    afp_retention: number = 0;
    sfs_retention: number = 0;
    contribution_retention: number = 0;
    general_info_entry_additional_retentions: IGeneralInfoEntryAdditionalRetention[] = [];
    afp_payment: number = 0;
    sfs_payment: number = 0;
    arl_payment: number = 0;
    infotep_payment: number = 0;
    contribution_payment: number = 0;
    tss_payment: number = 0;
    _destroy: boolean = false;

    constructor(init?: Partial<IGeneralInfoEntry>) {
        Object.assign(this, init);
    }
}

export interface IGeneralInfo {
    id: number;
    name: string;
    report_date: Date | null;
    general_info_entries: IGeneralInfoEntry[];
    updated_at: Date | null;
}

export class GeneralInfo implements IGeneralInfo {
    id: number = 0;
    name: string = `Datos Generales ${dayjs().format("MM-YY")}`;
    report_date: Date | null = new Date();
    general_info_entries: IGeneralInfoEntry[] = [];
    updated_at: Date | null = null;

    constructor(init?: Partial<IGeneralInfo>) {
        Object.assign(this, init);
    }
}
