import { Grid } from "@mui/material";
import React, { ReactNode, useContext } from "react";
import { RootStoreContext } from "../stores/root.store";
import { BackButton, NewButton } from "./Buttons.component";

interface Props {
    topButtons?: ReactNode;
}

const IndexTopSection = (props: Props) => {
    const { formStore } = useContext(RootStoreContext);

    return (
        <Grid container spacing={3} justifyContent={"space-between"} sx={{ mt: 2, mb: 2 }}>
            <Grid item container spacing={3} alignItems={"center"} xs={3}>
                <Grid item>
                    <BackButton />
                </Grid>
            </Grid>
            {props.topButtons}
            <Grid item>
                <NewButton onClick={formStore.handleNew} />
            </Grid>
        </Grid>
    )
}

export default IndexTopSection;
