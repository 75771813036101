import { makeAutoObservable } from "mobx";
import { EntityType, getEntityPath } from "../lib/util";
import { RootStore } from "./root.store";

export class DuplicateStore {
    open: boolean = false;
    id: number = 0;
    duplicateType: EntityType = EntityType.None;
    onDuplicate: () => void = () => { };
    
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    openDialog = (id: number, duplicateType: EntityType, onDuplicate: () => void) => {
        this.id = id;
        this.open = true;
        this.duplicateType = duplicateType;
        this.onDuplicate = onDuplicate;
    }

    confirmDuplicate = async () => {
        let path = getEntityPath(this.duplicateType);
        if (path !== "") {
            try {
                await this.rootStore.apiStore.duplicateEntry(path, this.id);
                this.onDuplicate();
                this.closeDialog();
            } catch (e) {
                console.log(e);
            }
        }
    }

    closeDialog = () => {
        this.id = 0;
        this.open = false;
        this.duplicateType = EntityType.None;
        this.onDuplicate = () => {
        };
    }
}