import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField
} from "@mui/material";
import React, { useEffect } from "react";
import { Control, Controller, UseFormGetValues, UseFormSetValue, useWatch } from "react-hook-form";
import { IOrder } from "../../../models/order";
import FormSectionTitle from "../../Form/FormSectionTitle.component";

interface Props {
    control: Control<IOrder>;
    getValues: UseFormGetValues<IOrder>;
    setValue: UseFormSetValue<IOrder>;
}

const OrderSealingForm = (props: Props) => {
    const refWidthWatcher = useWatch({ control: props.control, name: "reference_width" });
    const refHeightWatcher = useWatch({ control: props.control, name: "reference_height" });
    const giRequestedAmountWatcher = useWatch({ control: props.control, name: "general_info_requested_amount" });
    const slAmountPerPkgWatcher = useWatch({ control: props.control, name: "sealing_amount_per_package" });
    const slPkgsPerMasterCaseWatcher = useWatch({
        control: props.control,
        name: "sealing_packages_per_master_case_count"
    });

    useEffect(() => {
        const values = props.getValues();

        const width = Number(values.reference_width);
        const height = Number(values.reference_height);

        props.setValue("sealing_width", width);
        props.setValue("sealing_width_min", width);
        props.setValue("sealing_width_max", width + 0.02);

        props.setValue("sealing_length", height);
        props.setValue("sealing_length_min", height);
        props.setValue("sealing_length_max", height + 0.02);

        // Packages & Master case
        const amountPerMasterCase = values.sealing_amount_per_package * values.sealing_packages_per_master_case_count;
        props.setValue("sealing_amount_per_master_case", amountPerMasterCase);
        props.setValue("sealing_master_case_count", amountPerMasterCase > 0 ? values.general_info_requested_amount / amountPerMasterCase : 0);
    }, [refWidthWatcher, refHeightWatcher, slAmountPerPkgWatcher, giRequestedAmountWatcher, slPkgsPerMasterCaseWatcher]);

    return (
        <>
            <FormSectionTitle title={"Sealing"} />
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"sealing_side_bellows"}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Side Bellows (cms)"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"sealing_bottom_bellows"}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Bottom Bellows (cms)"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"sealing_side_seal"}
                    render={({ field: { value, ...field } }) => (
                        <FormControlLabel
                            {...field}
                            label={"Side Seal"}
                            control={<Checkbox checked={value} />}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"sealing_seal_bottom"}
                    render={({ field: { value, ...field } }) => (
                        <FormControlLabel
                            {...field}
                            label={"Bottom Seal"}
                            control={<Checkbox checked={value} />}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"sealing_seal_double_bottom"}
                    render={({ field: { value, ...field } }) => (
                        <FormControlLabel
                            label={"Double Bottom Seal"}
                            control={<Checkbox {...field} checked={value} />}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"sealing_cut_bottom"}
                    render={({ field: { value, ...field } }) => (
                        <FormControlLabel
                            label={"Bottom Cut"}
                            control={<Checkbox {...field} checked={value} />}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell variant={"head"}>Width</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'sealing_width'}
                                        render={({ field: { value } }) => (
                                            <span>{value?.toFixed(2)} cms</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Min. Width</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'sealing_width_min'}
                                        render={({ field: { value } }) => (
                                            <span>{value?.toFixed(2)} cms</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Max. Width</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'sealing_width_max'}
                                        render={({ field: { value } }) => (
                                            <span>{value?.toFixed(2)} cms</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={"head"}>Length</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'sealing_length'}
                                        render={({ field: { value } }) => (
                                            <span>{value?.toFixed(2)} cms</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Min. Length</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'sealing_length_min'}
                                        render={({ field: { value } }) => (
                                            <span>{value?.toFixed(2)} cms</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant={"head"}>Max. Length</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'sealing_length_max'}
                                        render={({ field: { value } }) => (
                                            <span>{value?.toFixed(2)} cms</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <FormSectionTitle title={"Packaging"} />
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"sealing_box_type"}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel id={"sealing-box-type"}>Boxes</InputLabel>
                            <Select
                                {...field}
                                label={"Boxes"}
                                labelId={"sealing-box-type"}
                                id={"sealing-box-type-select"}
                            >
                                <MenuItem value={0}>Fardos</MenuItem>
                                <MenuItem value={1}>12.5" x 12.5" x 6.25"</MenuItem>
                                <MenuItem value={2}>12.5" x 12.5" x 12.5"</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"sealing_amount_per_package"}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel id="sealing-amount-per-pkg">Amount / Pkg.</InputLabel>
                            <Select
                                {...field}
                                label={"Amount / Pkg."}
                                labelId="sealing-amount-per-pkg"
                                id={"sealing-amount-per-pkg-select"}
                            >
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={250}>250</MenuItem>
                                <MenuItem value={500}>500</MenuItem>
                                <MenuItem value={1000}>1000</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"sealing_packages_per_master_case_count"}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label={"Pkgs. / Master Case"}
                            fullWidth
                            type={"number"}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell variant={"head"}>Amount / Master Case</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'sealing_amount_per_master_case'}
                                        render={({ field: { value } }) => (
                                            <span>{value?.toLocaleString('en-US')} uds</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={"head"}>Master Case Count</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={'sealing_master_case_count'}
                                        render={({ field: { value } }) => (
                                            <span>{value?.toLocaleString('en-US')} uds</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default OrderSealingForm;
