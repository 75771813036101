import {makeAutoObservable} from "mobx";
import {RootStore} from "./root.store";
import {EntityType, getEntityPath} from "../lib/util";

export class DeleteStore {
    open: boolean = false;
    id: number = 0;
    deleteType: EntityType = EntityType.None;
    onDelete: () => void = () => {
    };
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    openDialog = (id: number, deleteType: EntityType, onDelete: () => void) => {
        this.id = id;
        this.open = true;
        this.deleteType = deleteType;
        this.onDelete = onDelete;
    }

    confirmDelete = async () => {
        let path = getEntityPath(this.deleteType);
        if (path !== "") {
            try {
                await this.rootStore.apiStore.deleteEntry(path, this.id);
                this.onDelete();
                this.closeDialog();
            } catch (e) {
                console.log(e);
            }
        }
    }

    closeDialog = () => {
        this.id = 0;
        this.open = false;
        this.deleteType = EntityType.None;
        this.onDelete = () => {
        };
    }
}