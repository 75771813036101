import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Close";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import React from "react";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { IOrder, OrderPrintInk } from "../../../models/order";

interface Props {
    control: Control<IOrder>;
    name: "order_print_ink_side_as" | "order_print_ink_side_bs";
}

const OrderPrintingInksSideForm = (props: Props) => {
    const { fields, append, remove } = useFieldArray({ control: props.control, name: props.name });

    return (
        <Grid item container direction={"column"} spacing={3}>
            {fields.map((field, fieldIndex) => (
                <Grid item container key={field.id} spacing={3} alignItems={"center"}>
                    <Grid item xs={10}>
                        <Controller
                            control={props.control}
                            name={`${props.name}.${fieldIndex}.color_name`}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label={"Color"}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            size={"small"}
                            color={"secondary"}
                            onClick={() => remove(fieldIndex)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Grid item sx={{ display: 'flex', justifyContent: "center" }}>
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    startIcon={<AddIcon />}
                    size={"small"}
                    onClick={() => append(new OrderPrintInk())}
                >
                    Add Ink
                </Button>
            </Grid>
        </Grid>
    )
}

export default OrderPrintingInksSideForm;
