import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import DuplicateIcon from "@mui/icons-material/FileCopy";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { EntityType } from "../../lib/util";
import { RootStoreContext } from "../../stores/root.store";

interface Props {
    id: number;
    onDelete: () => void;
    onDuplicate: () => void;
    entityType: EntityType;
    download?: () => void;
}

const TableActions = (props: Props) => {
    const { duplicateStore, deleteStore, formStore } = useContext(RootStoreContext);

    return (
        <Box sx={{ display: 'flex', gap: '1.5rem' }}>
            <Tooltip title="Edit">
                <IconButton
                    onClick={() => formStore.handleOpen(props.id)}
                    color={"info"}
                    size={"small"}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
            {props.download && (
                <Tooltip title="Download">
                    <IconButton
                        size={"small"}
                        color={"success"}
                        onClick={props.download}
                    >
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Duplicate">
                <IconButton
                    size={"small"}
                    color={"secondary"}
                    onClick={() => duplicateStore.openDialog(props.id, props.entityType, props.onDuplicate)}
                >
                    <DuplicateIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton
                    onClick={() => deleteStore.openDialog(props.id, props.entityType, props.onDelete)}
                    color={"error"}
                    size={"small"}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default TableActions;
