import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { Control, useFieldArray } from "react-hook-form";
import { IReport } from "../../models/report";

interface Props {
    control: Control<IReport, any>;
}

const ReportDetailsForm = (props: Props) => {
    const { fields: entryFields } = useFieldArray({ name: 'report_entries', control: props.control });

    return (
        <Grid container spacing={3} direction={"column"}>
            {entryFields.map((field, fieldIndex) => (
                <Grid item key={field.id}>
                    <TableContainer component={Paper}>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={1} sx={{ backgroundColor: 'black', color: 'red' }}>
                                        {field.employee?.name.toUpperCase()} DETAILS
                                    </TableCell>
                                    <TableCell colSpan={4} />
                                </TableRow>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align={"center"}>Events</TableCell>
                                    <TableCell align={"right"}>Regular</TableCell>
                                    <TableCell align={"right"}>Doubles</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {field.report_entry_days.filter(d => d.report_entry_day_events.length > 0).map((day, dayIndex) => (
                                    <TableRow key={`entry-${fieldIndex}-details-${dayIndex}`}>
                                        <TableCell sx={{ verticalAlign: 'top' }} variant={"head"}>
                                            {dayjs(day.entry_date).format("dddd DD")}
                                        </TableCell>
                                        <TableCell>
                                            <TableContainer>
                                                <Table size={"small"}>
                                                    <TableBody>
                                                        {day.report_entry_day_events.map((event, eventIndex) => (
                                                            <TableRow
                                                                key={`entry-${fieldIndex}-details-${dayIndex}-event-${eventIndex}`}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell align={"center"}>
                                                                    {dayjs(event.start_time).format("hh:mm a")}
                                                                </TableCell>
                                                                <TableCell align={"center"}>
                                                                    {dayjs(event.end_time).format("hh:mm a")}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            {day.worked_hours.toFixed(2)}
                                        </TableCell>
                                        <TableCell align={"right"}>
                                            {day.double_hours.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align={"right"} variant={"head"}>
                                        {field.worked_hours.toFixed(2)}
                                    </TableCell>
                                    <TableCell align={"right"} variant={"head"}>
                                        {field.double_hours.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            ))}
        </Grid>
    )
}

export default ReportDetailsForm;
