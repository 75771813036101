import {Grid} from "@mui/material";
import React, {useEffect} from "react";
import {Control, UseFormGetValues, UseFormSetValue, useFieldArray, useWatch} from "react-hook-form";
import {IReport} from "../../models/report";
import ReportEntryDayEventsForm from "./ReportEntryDayEventsForm";
import ReportEntryDaysFormTop from "./ReportEntryDaysFormTop";

interface Props {
    control: Control<IReport>;
    fieldIndex: number;
    setValue: UseFormSetValue<IReport>;
    getValues: UseFormGetValues<IReport>;
}

const ReportEntryDaysForm = (props: Props) => {
    const {fields: dayFields} = useFieldArray({
        name: `report_entries.${props.fieldIndex}.report_entry_days`,
        control: props.control
    });

    const dailySalaryWatcher = useWatch({
        control: props.control,
        name: `report_entries.${props.fieldIndex}.daily_salary`
    });

    const extraHoursWatcher = useWatch({
        control: props.control,
        name: `report_entries.${props.fieldIndex}.extra_hours`
    });

    const doubleHoursWatcher = useWatch({
        control: props.control,
        name: `report_entries.${props.fieldIndex}.double_hours`
    });

    const nightHoursWatcher = useWatch({
        control: props.control,
        name: `report_entries.${props.fieldIndex}.night_hours`
    });

    useEffect(() => {
        const hourlySalary = dailySalaryWatcher / 8.00;
        const percent35 = hourlySalary * 1.35;
        const percent15 = hourlySalary * 1.15;
        const doubleSalary = hourlySalary * 2;

        props.setValue(`report_entries.${props.fieldIndex}.hourly_salary`, hourlySalary);
        props.setValue(`report_entries.${props.fieldIndex}.percent_35`, percent35);
        props.setValue(`report_entries.${props.fieldIndex}.percent_15`, percent15);
        props.setValue(`report_entries.${props.fieldIndex}.double_salary`, doubleSalary);

        const extraHours = extraHoursWatcher > 0 ? extraHoursWatcher : 0;
        const nightHours = nightHoursWatcher > 0 ? nightHoursWatcher : 0;
        const doubleHours = doubleHoursWatcher > 0 ? doubleHoursWatcher : 0;

        let totalAmount = (percent35 * extraHours) + (percent15 * nightHours) + (doubleSalary * doubleHours);
        props.setValue(`report_entries.${props.fieldIndex}.total_amount`, totalAmount);
    }, [dailySalaryWatcher, extraHoursWatcher, doubleHoursWatcher, nightHoursWatcher]);

    return (
        <>
            <Grid item xs={12} md>
                <ReportEntryDaysFormTop
                    control={props.control}
                    fieldIndex={props.fieldIndex}
                    getValues={props.getValues}
                    setValue={props.setValue}
                />
            </Grid>
            <Grid container item spacing={3}>
                {dayFields.map((field, fieldIndex) => (
                    <Grid item xs={12} md={4} key={field.id}>
                        <ReportEntryDayEventsForm
                            control={props.control}
                            entryIndex={props.fieldIndex}
                            dayIndex={fieldIndex}
                            getValues={props.getValues}
                            setValue={props.setValue}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default ReportEntryDaysForm;
