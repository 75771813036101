import { makeAutoObservable } from "mobx";
import { IEmployee } from "../models/employee";
import { IGeneralInfo } from "../models/generalInfo";
import { IMonthlyReportEntry, IReport, Report } from "../models/report";
import { RootStore } from "./root.store";
import { IOrder, IOrdersResponse, OrdersResponse } from "../models/order";
import { IPayroll, Payroll } from "../models/payroll";
import dayjs, { Dayjs } from "dayjs";
import { IItem } from "../models/item";
import { enqueueSnackbar } from "notistack";
import Util from "../lib/util";
import { IOrderClientReport, IOrderProductReport } from "../models/orderReport";
import { IPaginated } from "../lib/paginated";

const contentHeader = {
    "Content-Type": "application/json",
}

export class ApiStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    // Authentication
    login = async (email: string, password: string) => {
        const response = await fetch('api/v1/authentication', {
            method: "POST",
            body: JSON.stringify({ email, password }),
            headers: { ...contentHeader }
        });
        const respBody = await response.json();
        if (response.ok) {
            const token = respBody.token;
            localStorage.setItem('token', token);
            this.rootStore.authStore.setToken(email, token);
        } else {
            throw new Error(respBody.error);
        }
    }

    checkLogin = async () => {
        if (this.rootStore.authStore.token) {
            const response = await fetch('api/v1/authentication/check', {
                method: "GET",
                headers: this.rootStore.authStore.authHeader
            });
            if (response.ok) {
                const body = await response.json();
                this.rootStore.authStore.setEmail(body.user_email);
            } else {
                localStorage.removeItem('token');
                this.rootStore.authStore.logout();
                throw new Error("Please login again");
            }
        }
    }

    // Employees
    getEmployees = async (pageIndex: number, pageSize: number, search: string | null, order: string | null): Promise<IPaginated<IEmployee>> => {
        const result: IPaginated<IEmployee> = {
            data: [],
            current_page: 0,
            total_pages: 0,
            total_count: 0
        };
        try {
            const params = new URLSearchParams({
                page: (pageIndex + 1).toString(),
                per_page: pageSize.toString(),
                search: search ?? "",
                order: order ?? "",
            })
            const response = await fetch(`api/v1/employees?${params}`, { headers: this.headers })
            if (response.ok) {
                const jsonResponse = await response.json();
                result.data = jsonResponse.data;
                result.current_page = jsonResponse.current_page;
                result.total_pages = jsonResponse.total_pages;
                result.total_count = jsonResponse.total_count
            } else {
                throw new Error("Error while fetching employees");
            }
        } catch (e) {
            console.log(e);
        }
        return result;
    }

    getEmployee = async (id: number): Promise<IEmployee> => {
        try {
            const response = await fetch(`api/v1/employees/${id}`, {
                headers: this.headers
            });
            if (response.ok) {
                return await response.json();
            } else {
                throw new Error(`Error while fetching employee with ID ${id}`);
            }
        } catch (e) {
            throw e;
        }
    }

    submitEmployee = async (values: IEmployee, id: number) => {
        try {
            const body = JSON.stringify(values);
            if (id > 0) {
                const response = await fetch(`api/v1/employees/${id}`, {
                    method: "PUT", body, headers: this.headers
                })
                if (!response.ok) {
                    throw new Error(`Error while updating Employee with ID ${id}`);
                }
            } else {
                const response = await fetch('api/v1/employees/', { method: "POST", body, headers: this.headers });
                if (!response.ok) {
                    throw new Error("Error while creating a new Employee");
                }
            }
        } catch (e) {
            throw e;
        }
    }

    // General Info
    getGeneralInfos = async (): Promise<IGeneralInfo[]> => {
        let infos: IGeneralInfo[] = [];
        try {
            const response = await fetch('api/v1/general_infos', { headers: this.headers });
            if (response.ok) {
                infos = await response.json();
            } else {
                throw new Error("Error while fetching General Infos");
            }
        } catch (e) {
            console.log(e);
        }
        return infos;
    }

    getGeneralInfo = async (id: number): Promise<IGeneralInfo> => {
        try {
            const response = await fetch(`api/v1/general_infos/${id}`, { headers: this.headers });
            if (response.ok) {
                return await response.json();
            } else {
                throw new Error(`Error while fetching General Info with ID ${id}`);
            }
        } catch (e) {
            throw e;
        }
    }

    submitGeneralInfo = async (values: IGeneralInfo, id: number) => {
        try {
            const body = JSON.stringify({ general_info: values })
            if (id > 0) {
                const response = await fetch(`api/v1/general_infos/${id}`, {
                    method: "PUT", body, headers: this.headers
                })
                if (!response.ok) {
                    throw new Error(`Error while updating General Info with ID ${id}`);
                }
            } else {
                const response = await fetch('api/v1/general_infos/', { method: "POST", body, headers: this.headers });
                if (!response.ok) {
                    throw new Error("Error while creating a new General Info");
                }
            }
        } catch (e) {
            throw e;
        }
    }

    // Reports
    getReports = async (): Promise<IReport[]> => {
        let reports: IReport[] = [];
        try {
            const response = await fetch('api/v1/reports', { headers: this.headers });
            if (response.ok) {
                reports = await response.json();
            } else {
                throw new Error("Error while fetching Reports");
            }
        } catch (e) {
            console.log(e);
        }
        return reports;
    }

    parseTimeCard = async (body: FormData) => {
        try {
            const response = await fetch('api/v1/reports/parse_time_card_report', {
                method: "POST", body, headers: { ...this.rootStore.authStore.authHeader }
            });
            if (response.ok) {
                const baseReport = await response.json();
                return new Report({ ...baseReport })
            } else {
                throw new Error("Error while parsing Time Card");
            }
        } catch (e) {
            throw e;
        }
    }

    getMonthlyReport = async (myDate: Dayjs | null): Promise<IMonthlyReportEntry[]> => {
        if (myDate === null) {
            return [];
        }

        const urlParams = new URLSearchParams({
            date: myDate.toISOString()
        })
        const response = await fetch(`api/v1/reports/show_monthly_report?${urlParams}`, {
            headers: { ...this.rootStore.authStore.authHeader }
        });

        return await response.json();
    }

    getReport = async (id: number): Promise<IReport> => {
        try {
            const response = await fetch(`api/v1/reports/${id}`, { headers: this.headers });
            if (response.ok) {
                return await response.json();
            } else {
                throw new Error(`Error while fetching Report with ID ${id}`);
            }
        } catch (e) {
            throw e;
        }
    }

    getReportsByMonth = async (month: number, year: number): Promise<IReport[]> => {
        try {
            const urlParams = new URLSearchParams({
                month: month.toString(),
                year: year.toString()
            })
            const response = await fetch(`api/v1/reports/get_by_month?${urlParams}`, { headers: this.headers });
            return await response.json();
        } catch (e) {
            throw e;
        }
    }

    downloadReportExcel = async (report: IReport) => {
        try {
            const response = await fetch("api/v1/reports/generate_details_excel", {
                method: "POST",
                body: JSON.stringify({ report: report }),
                headers: this.headers
            });
            const data = await response.blob();
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(data);
            a.download = `${report.name || "Report"}.xlsx`
            a.click();
        } catch (e) {
            throw new Error("Error while downloading Report's Excel file");
        }
    }

    submitReport = async (values: IReport, id: number) => {
        try {
            const body = JSON.stringify({ report: values });
            if (id > 0) {
                const response = await fetch(`api/v1/reports/${id}`, { method: "PUT", body, headers: this.headers });
                if (!response.ok) {
                    throw new Error(`Error while updating Report with ID ${id}`);
                }
            } else {
                const response = await fetch('api/v1/reports', { method: "POST", body, headers: this.headers });
                if (!response.ok) {
                    throw new Error("Error while creating a new Report");
                }
            }
        } catch (e) {
            throw e;
        }
    }

    // Payrolls
    getPayrolls = async (): Promise<Payroll[]> => {
        let payrolls: IPayroll[] = [];
        try {
            const response = await fetch('api/v1/payrolls', { headers: this.headers });
            if (response.ok) {
                payrolls = await response.json();
            } else {
                throw new Error("Error while fetching Payrolls");
            }
        } catch (e) {
            console.log(e);
        }
        return payrolls;
    }

    getPayroll = async (id: number): Promise<IPayroll> => {
        const response = await fetch(`api/v1/payrolls/${id}`, { headers: this.headers });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Error while fetching Payroll with ID ${id}`);
        }
    }

    downloadPayrollExcelFromObject = async (payroll: IPayroll) => {
        try {
            const response = await fetch("api/v1/payrolls/excel-object", {
                method: "POST",
                body: JSON.stringify({ payroll: payroll }),
                headers: this.headers
            });
            const data = await response.blob();
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(data);
            a.download = decodeURIComponent(response.headers.get('X-Filename') || "Nómina.xlsx");
            a.click();
        } catch (e) {
            throw new Error("Error while downloading Payroll's Excel file");
        }
    }

    downloadPayrollExcel = async (id: number) => {
        try {
            const response = await fetch(`api/v1/payrolls/${id}/excel`, {
                method: "GET",
                headers: this.headers
            });
            const data = await response.blob();
            const a = document.createElement("a");
            a.href = window.URL.createObjectURL(data);
            a.download = decodeURIComponent(response.headers.get('X-Filename') || "Nómina.xlsx");
            a.click();
        } catch (e) {
            throw new Error("Error while downloading Payroll's Excel file");
        }
    }

    submitPayroll = async (values: IPayroll, id: number) => {
        const body = JSON.stringify({ payroll: values });
        if (id > 0) {
            const response = await fetch(`api/v1/payrolls/${id}`, {
                method: "PUT",
                body,
                headers: this.headers
            });
            if (!response.ok) {
                throw new Error(`Error while updating Payroll with ID: ${id}`);
            }
        } else {
            const response = await fetch('api/v1/payrolls', {
                method: "POST",
                body,
                headers: this.headers
            });
            if (!response.ok) {
                throw new Error("Error while creating a new Payroll");
            }
        }
    }

    // Orders
    getOrders = async (page: number = 1, search: string = "", pageSize: number = 25): Promise<IOrder[]> => {
        let orders: IOrder[] = [];
        try {
            const fetchUrl = 'api/v1/orders'
            // let fetchUrl = `api/v1/orders?page=${page}&page_size=${pageSize}`;
            // if (search.length > 0) {
            //     fetchUrl += `&search=${search}`;
            // }
            const response = await fetch(fetchUrl, { headers: this.headers });
            if (response.ok) {
                orders = await response.json();
            } else {
                throw new Error("Error while fetching Reports");
            }
        } catch (e) {
            console.log(e);
        }
        return orders;
    }

    getWithLatestCode = async (date: Date): Promise<IOrder> => {
        try {
            const myDate = dayjs(date);
            const urlParams = new URLSearchParams({
                month: (myDate.month() + 1).toString(),
                year: myDate.year().toString()
            });
            const response = await fetch(`api/v1/orders/show_with_latest_code?${urlParams}`, {
                headers: this.headers
            })
            if (response.ok) {
                return await response.json();
            } else {
                throw new Error('Error while fetching the latest Order with code');
            }
        } catch (e) {
            throw e;
        }
    }

    getOrder = async (id: number): Promise<IOrder> => {
        try {
            const response = await fetch(`api/v1/orders/${id}`, { headers: this.headers });
            if (response.ok) {
                return await response.json();
            } else {
                throw new Error(`Error while fetching Order with ID ${id}`);
            }
        } catch (e) {
            throw e;
        }
    }

    submitOrder = async (values: IOrder, id: number) => {
        const body = JSON.stringify({ order: values });
        if (id > 0) {
            const response = await fetch(`api/v1/orders/${id}`, {
                method: "PUT",
                body,
                headers: this.headers
            });
            const respBody = await response.json();
            if (!response.ok) {
                throw new Error(respBody.errors.join('\n'));
            }
        } else {
            const response = await fetch('api/v1/orders', {
                method: "POST",
                body,
                headers: this.headers
            });
            const respBody = await response.json();
            if (!response.ok) {
                throw new Error(respBody.errors.join('\n'));
            }
        }
    }

    downloadOrderExcel = async (id: number) => {
        try {
            const response = await fetch(`api/v1/orders/${id}/generate_order_excel`, {
                headers: this.headers
            });
            if (response.ok) {
                const filename = Util.getFilenameFromHeaders(response.headers, "Order");

                const data = await response.blob();
                const a = document.createElement("a");
                a.href = window.URL.createObjectURL(data);
                a.download = `${filename}.xlsx`
                a.click();
            }
        } catch (e) {
            throw new Error("Error while downloading Order's Excel file");
        }
    }

    downloadOrdersByMonthExcel = async (ids: number[], date: Date) => {
        try {
            const response = await fetch(`api/v1/orders/generate_orders_group_excel`, {
                headers: this.headers,
                method: "POST",
                body: JSON.stringify({
                    orders_ids: ids,
                    date: date
                })
            })

            if (response.ok) {
                const filename = Util.getFilenameFromHeaders(response.headers, "Orders");

                const data = await response.blob();
                const a = document.createElement("a");
                a.href = window.URL.createObjectURL(data);
                a.download = `${filename}.xlsx`;
                a.click();
            }
        } catch (e) {
            throw new Error("Error while downloading multiple Orders Excel files");
        }
    }

    getOrdersByMonth = async (date: Dayjs): Promise<IOrder[]> => {
        try {
            const urlParams = new URLSearchParams({
                month: (date.month() + 1).toString(),
                year: date.year().toString()
            });

            const response = await fetch(`api/v1/orders/get_by_month?${urlParams}`, {
                method: "GET",
                headers: this.headers
            })
            if (response.ok) {
                return await response.json();
            }
            return [];
        } catch (e) {
            throw e;
        }
    }

    getItems = async (): Promise<IItem[]> => {
        try {
            const response = await fetch('api/v1/orders/show_mix_items', {
                method: "GET",
                headers: this.headers
            })
            if (response.ok) {
                return await response.json();
            }
            return [];
        } catch (e) {
            throw e;
        }
    }

    getOrdersReportByClient = async (startDate: Dayjs, endDate: Dayjs): Promise<IOrderClientReport[]> => {
        try {
            const urlParams = new URLSearchParams({
                start_date: startDate.format("YYYY-MM-DD"),
                end_date: endDate.format("YYYY-MM-DD"),
            });
            const response = await fetch(`api/v1/orders/orders_per_client_report.json?${urlParams}`, {
                method: "GET",
                headers: this.headers
            });
            if (response.ok) {
                return await response.json();
            }
            return [];
        } catch (e) {
            throw e;
        }
    }

    downloadOrdersByClientReportExcel = async (startDate: Dayjs, endDate: Dayjs) => {
        try {
            const urlParams = new URLSearchParams({
                start_date: startDate.format("YYYY-MM-DD"),
                end_date: endDate.format("YYYY-MM-DD"),
            });
            const response = await fetch(`api/v1/orders/orders_per_client_report.xlsx?${urlParams}`, {
                method: "GET",
                headers: this.headers
            });
            if (response.ok) {
                const filename = Util.getFilenameFromHeaders(response.headers, "Orders Report by Client");

                const data = await response.blob();
                const a = document.createElement("a");
                a.href = window.URL.createObjectURL(data);
                a.download = `${filename}.xlsx`;
                a.click();
            }
        } catch (e) {
            throw new Error("Error while downloading Orders Report by Client Excel");
        }
    }

    getOrdersByProductReport = async (startDate: Dayjs, endDate: Dayjs): Promise<IOrderProductReport[]> => {
        try {
            const urlParams = new URLSearchParams({
                start_date: startDate.format("YYYY-MM-DD"),
                end_date: endDate.format("YYYY-MM-DD"),
            });
            const response = await fetch(`api/v1/orders/orders_per_product_report.json?${urlParams}`, {
                method: "GET",
                headers: this.headers
            });
            if (response.ok) {
                return await response.json();
            }
            return [];
        } catch (e) {
            throw e;
        }
    }

    downloadOrdersByProductReportExcel = async (startDate: Dayjs, endDate: Dayjs) => {
        try {
            const urlParams = new URLSearchParams({
                start_date: startDate.format("YYYY-MM-DD"),
                end_date: endDate.format("YYYY-MM-DD"),
            });
            const response = await fetch(`api/v1/orders/orders_per_product_report.xlsx?${urlParams}`, {
                method: "GET",
                headers: this.headers
            });
            if (response.ok) {
                const filename = Util.getFilenameFromHeaders(response.headers, "Orders Report by Product");

                const data = await response.blob();
                const a = document.createElement("a");
                a.href = window.URL.createObjectURL(data);
                a.download = `${filename}.xlsx`;
                a.click();
            }
        } catch (e) {
            throw new Error("Error while downloading Orders Report by Product Excel");
        }
    }

    //
    // POST to ID duplicates the entry
    duplicateEntry = async (path: string, id: number) => {
        try {
            const response = await fetch(`api/v1/${path}/${id}`, {
                method: "POST",
                headers: this.headers
            });
            const json = await response.json();

            if (json.error !== undefined) {
                enqueueSnackbar(json.error.join("\n"), { variant: "error" });
                throw new Error(json.error);
            }
        } catch (e) {
            throw e;
        }
    }

    deleteEntry = async (path: string, id: number) => {
        const response = await fetch(`api/v1/${path}/${id}`, {
            method: "DELETE", headers: this.headers
        });
        if (!response.ok) {
            throw new Error("Something went wrong");
        }
    }

    get headers() {
        return {
            ...this.rootStore.authStore.authHeader,
            ...contentHeader
        }
    }
}
