import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import React from "react"
import {Control, useWatch} from "react-hook-form"
import {getEmployeeFullName} from "../../lib/util"
import {IReport} from "../../models/report"

interface Props {
    control: Control<IReport, any>;
}

const ReportHoursTableForm = (props: Props) => {
    const entriesWatcher = useWatch({control: props.control, name: "report_entries"});

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Employee</TableCell>
                        <TableCell align={"right"}>Daily</TableCell>
                        <TableCell align={"right"}>Hourly</TableCell>
                        <TableCell align={"right"}>35%</TableCell>
                        <TableCell align={"right"}>15%</TableCell>
                        <TableCell align={"right"}>Double</TableCell>
                        <TableCell align={"right"}>Extra Hours</TableCell>
                        <TableCell align={"right"}>Double Hours</TableCell>
                        <TableCell align={"right"}>Night Hours</TableCell>
                        <TableCell align={"right"}>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {entriesWatcher.map((entry, entryIndex) => (
                        <TableRow key={`${entry.id}-${entryIndex}`}>
                            <TableCell>
                                {getEmployeeFullName(entry.employee)}
                            </TableCell>
                            <TableCell align="right">
                                $&nbsp;{entry.daily_salary.toFixed(2)}
                            </TableCell>
                            <TableCell align={"right"}>
                                $&nbsp;{entry.hourly_salary.toFixed(2)}
                            </TableCell>
                            <TableCell align={"right"}>
                                $&nbsp;{entry.percent_35.toFixed(2)}
                            </TableCell>
                            <TableCell align={"right"}>
                                $&nbsp;{entry.percent_15.toFixed(2)}
                            </TableCell>
                            <TableCell align={"right"}>
                                $&nbsp;{entry.double_salary.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                                {entry.extra_hours > 0 ? entry.extra_hours.toFixed(2) : '-'}
                            </TableCell>
                            <TableCell align="right">
                                {entry.double_hours.toFixed(2)}
                            </TableCell>
                            <TableCell align={"right"}>
                                {entry.night_hours > 0 ? entry.night_hours.toFixed(2) : '-'}
                            </TableCell>
                            <TableCell align={"right"}>
                                $&nbsp;{entry.total_amount.toFixed(2)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ReportHoursTableForm;
