import CloseIcon from "@mui/icons-material/Close";
import {Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, Typography} from "@mui/material";
import {TimePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import React, {useEffect, useMemo} from "react";
import {Control, Controller, useFieldArray, UseFormGetValues, UseFormSetValue, useWatch} from "react-hook-form";
import {getDayDateWithName} from "../../lib/time";
import {getDayDoubleHours, getDayExtraHours, getDayNightHours, getDayWorkedHours} from "../../lib/util";
import {IReport, ReportEntryDayEvent} from "../../models/report";
import BorderBlock from "../BorderBlock.component";

interface Props {
    control: Control<IReport>;
    entryIndex: number;
    dayIndex: number;
    setValue: UseFormSetValue<IReport>;
    getValues: UseFormGetValues<IReport>;
}

const ReportEntryDayEventsForm = (props: Props) => {
    const {fields: eventFields, append: eventAppend, remove: eventRemove} = useFieldArray({
        name: `report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.report_entry_day_events`,
        control: props.control
    });

    const dayWatcher = useWatch({
        control: props.control,
        name: `report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.entry_date`
    });

    const eventsWatcher = useWatch({
        control: props.control,
        name: `report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.report_entry_day_events`
    });

    const isHolidayWatcher = useWatch({
        control: props.control,
        name: `report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.is_holiday`
    });

    const myCurrentDay = useMemo(() => {
        const dayDate = dayjs(dayWatcher);
        return dayDate.day();
    }, [dayWatcher]);

    const handleAddEvent = () => {
        const today = dayjs();
        const currentTime = dayjs(dayWatcher).set('hour', today.hour()).set('minute', today.minute()).toDate();
        eventAppend(new ReportEntryDayEvent({
            start_time: currentTime,
            end_time: currentTime
        }));
    }

    const handleRemoveEvent = (index: number) => {
        eventRemove(index);
    }

    const handleEventTimeChange = (name: "start_time" | "end_time", index: number, time: Dayjs | null) => {
        let event = props.getValues().report_entries[props.entryIndex].report_entry_days[props.dayIndex].report_entry_day_events[index];
        event[name] = time?.toDate() || null;

        props.setValue(
            `report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.report_entry_day_events.${index}`,
            event
        );
    }

    useEffect(() => {
        const values = props.getValues();
        let entry = values.report_entries[props.entryIndex];
        let entryDay = entry.report_entry_days[props.dayIndex];

        props.setValue(
            `report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.worked_hours`,
            getDayWorkedHours(entryDay)
        );

        props.setValue(
            `report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.extra_hours`,
            getDayExtraHours(entryDay)
        );

        props.setValue(
            `report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.double_hours`,
            getDayDoubleHours(entryDay)
        );

        props.setValue(
            `report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.night_hours`,
            getDayNightHours(entryDay)
        )

        props.setValue(
            `report_entries.${props.entryIndex}.worked_hours`,
            entry.report_entry_days
                .map(d => getDayWorkedHours(d))
                .reduce((a, c) => a + c, 0)
        );

        props.setValue(
            `report_entries.${props.entryIndex}.double_hours`,
            entry.report_entry_days
                .map(d => getDayDoubleHours(d))
                .reduce((a, c) => a + c, 0)
        );

        props.setValue(
            `report_entries.${props.entryIndex}.night_hours`,
            entry.report_entry_days
                .map(d => getDayNightHours(d))
                .reduce((a, c) => a + c, 0)
        )
    }, [eventsWatcher, isHolidayWatcher]);

    return (
        <BorderBlock
            title={
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography>
                            <strong>{getDayDateWithName(dayWatcher)}</strong>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Controller
                            control={props.control}
                            name={`report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.worked_hours`}
                            render={({field}) => (
                                <Typography>
                                    <strong>Worked: {field.value.toFixed(2)}</strong>
                                </Typography>
                            )}
                        />
                    </Grid>
                    <Controller
                        control={props.control}
                        name={`report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.extra_hours`}
                        render={({field}) => (
                            <Grid item sx={{display: field.value <= 0 ? "none" : "block"}}>
                                <Typography>
                                    <strong>Extra: {field.value.toFixed(2)}</strong>
                                </Typography>
                            </Grid>
                        )}
                    />
                    <Controller
                        control={props.control}
                        name={`report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.double_hours`}
                        render={({field}) => (
                            <Grid item sx={{display: field.value <= 0 ? "none" : "block"}}>
                                <Typography>
                                    <strong>Double: {field.value.toFixed(2)}</strong>
                                </Typography>
                            </Grid>
                        )}
                    />
                    <Controller
                        control={props.control}
                        name={`report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.night_hours`}
                        render={({field}) => (
                            <Grid item sx={{display: field.value <= 0 ? "none" : "block"}}>
                                <Typography>
                                    <strong>Night: {field.value.toFixed(2)}</strong>
                                </Typography>
                            </Grid>
                        )}
                    />
                </Grid>
            }
        >
            <Grid container spacing={3} direction={"column"}>
                {eventFields.map((field, fieldIndex) => (
                    <Grid container item spacing={3} alignItems={"center"} key={field.id}>
                        <Grid item xs={12} md={5}>
                            <Controller
                                control={props.control}
                                name={`report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.report_entry_day_events.${fieldIndex}.start_time`}
                                render={({field: {ref, value}}) => (
                                    <TimePicker
                                        inputRef={ref}
                                        value={dayjs(value)}
                                        label={"Start Time"}
                                        onChange={data => handleEventTimeChange("start_time", fieldIndex, data)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Controller
                                control={props.control}
                                name={`report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.report_entry_day_events.${fieldIndex}.end_time`}
                                render={({field: {ref, value}}) => (
                                    <TimePicker
                                        inputRef={ref}
                                        value={dayjs(value)}
                                        label={"End Time"}
                                        onChange={data => handleEventTimeChange("end_time", fieldIndex, data)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton size={"small"}
                                        onClick={() => handleRemoveEvent(fieldIndex)}>
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Grid container item direction={"column"} spacing={1}>
                    <Grid item>
                        <Divider/>
                    </Grid>
                    <Grid item container spacing={3} justifyContent={"space-between"}>
                        <Grid item>
                            {myCurrentDay !== 0 && (
                                <Controller
                                    control={props.control}
                                    name={`report_entries.${props.entryIndex}.report_entry_days.${props.dayIndex}.is_holiday`}
                                    render={({field}) => (
                                        <FormControlLabel
                                            label={(
                                                <Typography>
                                                    <strong>Holiday</strong>
                                                </Typography>
                                            )}
                                            control={(
                                                <Checkbox
                                                    {...field}
                                                    size="small"/>
                                            )}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleAddEvent}>
                                Add Events
                            </Button>
                        </Grid>
                    </Grid>
                    {/*<Grid item sx={{*/}
                    {/*    display: "flex",*/}
                    {/*    justifyContent: "flex-end"*/}
                    {/*}}>*/}
                    {/*    <Button*/}
                    {/*        onClick={handleAddEvent}>*/}
                    {/*        Add Events*/}
                    {/*    </Button>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
        </BorderBlock>
    )
}

export default ReportEntryDayEventsForm;
