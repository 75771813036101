import { Box, Card, CardActionArea, CardContent, Divider, Typography } from "@mui/material"
import React, { ReactNode } from "react"

interface Props {
    title: string
    icon: ReactNode
    description: string
    onClick: () => void
}

const MenuCard = (props: Props) => {
    return (
        <Card sx={{ width: '100%' }}>
            <CardActionArea onClick={props.onClick}>
                <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant={"h6"} textAlign={"left"}>
                            {props.title}
                        </Typography>
                        <Divider />
                        <Box sx={{ textAlign: 'center' }}>
                            {props.icon}
                        </Box>
                        <Typography variant={"body2"} textAlign={"center"}>
                            {props.description}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default MenuCard;
