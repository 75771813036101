import {Box, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import OrdersReportsContent from "./OrdersReportsContent";

interface Props {
    open: boolean;
    onClose: () => void;
}

const OrdersReportsDialog = (props: Props) => {
    return (
        <Dialog
            open={props.open}
            maxWidth={"xl"}
            fullWidth
        >
            <DialogTitle>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Typography component={"h1"} variant={"h6"}>
                        Orders | Reports
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <OrdersReportsContent/>
            </DialogContent>
        </Dialog>
    )
}

export default OrdersReportsDialog;
