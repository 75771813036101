import {CssBaseline, ThemeProvider, createTheme} from "@mui/material";
import {grey} from "@mui/material/colors";
import {observer} from "mobx-react";
import React, {useContext, useMemo} from "react";
import {RootStoreContext} from "./stores/root.store";
import {SnackbarProvider} from "notistack";
import Main from "./Main";

const App = () => {
    const {authStore} = useContext(RootStoreContext);
    const theme = useMemo(() => createTheme({
        palette: {
            mode: authStore.theme,
            primary: {
                main: "#E1523E"
            },
            secondary: {
                main: grey[400]
            }
        }
    }), [authStore.darkMode]);

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                autoHideDuration={3000}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "top"
                }}
            >
                <CssBaseline/>
                <Main/>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default observer(App);
