import { Box, CircularProgress } from "@mui/material";
import AppRouter from "./AppRouter";
import DuplicateDialog from "./components/DuplicateDialog.component";
import DeleteConfirmDialog from "./components/DeleteDialog.component";
import React, { useContext, useEffect, useState } from "react";
import { useToast } from "./lib/customHooks";
import { RootStoreContext } from "./stores/root.store";

const Main = () => {
    const { apiStore } = useContext(RootStoreContext);

    const [loading, setLoading] = useState(true);
    const { error, info } = useToast();

    useEffect(() => {
        (async () => {
            try {
                await apiStore.checkLogin();
            } catch (e: any) {
                info(e.message);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    if (loading) {
        return (
            <Box sx={{
                width: '100%',
                height: '100vh',
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <>
            <AppRouter />
            <DuplicateDialog />
            <DeleteConfirmDialog />
        </>
    )
}

export default Main;