import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {Control, Controller, UseFormGetValues, UseFormSetValue, useWatch} from "react-hook-form";
import {IOrder} from "../../../models/order";
import FormSectionTitle from "../../Form/FormSectionTitle.component";
import OrderPrintingInksSideForm from "./OrderPrintingInksSideForm";

interface Props {
    control: Control<IOrder>;
    getValues: UseFormGetValues<IOrder>;
    setValue: UseFormSetValue<IOrder>;
}

const OrderPrintingForm = (props: Props) => {
    const totalKgWatcher = useWatch({control: props.control, name: 'general_info_programmed_total'});
    const rollWeightWatcher = useWatch({control: props.control, name: "final_product_weight_per_roll"});

    useEffect(() => {
        const values = props.getValues();

        const rollsNumber = values.general_info_programmed_total / values.final_product_weight_per_roll;

        props.setValue('printing_rolls_number', rollsNumber);
        props.setValue('printing_kg_per_roll', values.final_product_weight_per_roll);
        props.setValue('printing_mt_per_roll', ((rollsNumber * 1000) / (values.reference_width * values.extrusion_caliber * 0.004)) / 100);
    }, [totalKgWatcher, rollWeightWatcher]);

    return (
        <>
            <FormSectionTitle title="Printing"/>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"printer_number"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Printer No."}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"printing_width"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Width"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"printing_height"}
                    render={({field}) => (
                        <TextField
                            {...field}
                            label={"Height"}
                            type={"number"}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"printing_inks_amount"}
                    render={({field}) => (
                        <FormControl fullWidth>
                            <InputLabel id={"inks-amount-select-label"}>Inks Amount</InputLabel>
                            <Select
                                {...field}
                                label={"Inks Amount"}
                                labelId={"inks-amount-select-label"}
                                id={"inks-amount-select"}
                            >
                                <MenuItem value={0}>No Ink</MenuItem>
                                <MenuItem value={1}>1 Ink</MenuItem>
                                <MenuItem value={2}>2 Inks</MenuItem>
                                <MenuItem value={3}>3 Inks</MenuItem>
                                <MenuItem value={4}>4 Inks</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"printing_roller"}
                    render={({field}) => (
                        <FormControl fullWidth>
                            <InputLabel id={"roller-select-label"}>Roller</InputLabel>
                            <Select
                                {...field}
                                label={"Roller"}
                                labelId={"roller-select-label"}
                                id={"roller-select"}
                            >
                                <MenuItem value={0}>No roller</MenuItem>
                                <MenuItem value={25}>25 cm.</MenuItem>
                                <MenuItem value={28}>28 cm.</MenuItem>
                                <MenuItem value={30}>30 cm.</MenuItem>
                                <MenuItem value={33}>33 cm.</MenuItem>
                                <MenuItem value={35}>35 cm.</MenuItem>
                                <MenuItem value={37}>37 cm.</MenuItem>
                                <MenuItem value={40}>40 cm.</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"printing_mounting"}
                    render={({field}) => (
                        <FormControl fullWidth>
                            <InputLabel id={"mounting-select-label"}>Mounting</InputLabel>
                            <Select
                                {...field}
                                label={"Mounting"}
                                labelId={"mounting-select-label"}
                                id={"mounting-select"}
                            >
                                <MenuItem value={0}>No mounting</MenuItem>
                                <MenuItem value={1}>Single</MenuItem>
                                <MenuItem value={2}>Double</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <Controller
                    control={props.control}
                    name={"printing_threaded"}
                    render={({field}) => (
                        <FormControl fullWidth>
                            <InputLabel id={"threaded-select-label"}>Threaded</InputLabel>
                            <Select
                                {...field}
                                label={"Threaded"}
                                labelId={"threaded-select-label"}
                                id={"threaded-select"}
                            >
                                <MenuItem value={0}>No threaded</MenuItem>
                                <MenuItem value={1}>N.</MenuItem>
                                <MenuItem value={2}>V.</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">Rolls No.</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={"printing_rolls_number"}
                                        render={({field: {value}}) => (
                                            <span>{value?.toFixed(5)}</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant="head">Kg. per Roll</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={"printing_kg_per_roll"}
                                        render={({field: {value}}) => (
                                            <span>{value?.toFixed(1)}</span>
                                        )}
                                    />
                                </TableCell>
                                <TableCell variant="head">Mt. per Roll</TableCell>
                                <TableCell>
                                    <Controller
                                        control={props.control}
                                        name={"printing_mt_per_roll"}
                                        render={({field: {value}}) => (
                                            <span>{value?.toFixed(1)}</span>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {/* Printing Inks */}
            <Grid item container spacing={3} alignItems={"flex-start"}>
                {/* Side A */}
                <Grid item container xs={12} md={6} spacing={3} alignItems={"flex-start"}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"}>
                            Side A
                        </Typography>
                    </Grid>
                    <OrderPrintingInksSideForm
                        control={props.control}
                        name="order_print_ink_side_as"
                    />
                </Grid>
                {/* Side B */}
                <Grid item container xs={12} md={6} spacing={3} alignItems={"flex-start"}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"}>
                            Side B
                        </Typography>
                    </Grid>
                    <OrderPrintingInksSideForm
                        control={props.control}
                        name="order_print_ink_side_bs"
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default OrderPrintingForm;
