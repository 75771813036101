import AddIcon from "@mui/icons-material/Add";
import { Autocomplete, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Control, Controller, UseFormGetValues, UseFormSetValue, useFieldArray } from "react-hook-form";
import { formatFpcId } from "../../lib/format";
import { IEmployee } from "../../models/employee";
import { IPayroll, PayrollEntry } from "../../models/payroll";
import { IReport } from "../../models/report";
import BorderBlock from "../BorderBlock.component";
import FpcEmployeeTitle from "../FpcEmployeeTitle.component";
import PayrollEntryDaysForm from "./PayrollEntryDaysForm";
import PayrollEntrySalaryForm from "./PayrollEntrySalaryForm";
import { IGeneralInfo } from "../../models/generalInfo";

interface Props {
    control: Control<IPayroll>;
    employees: IEmployee[];
    setValue: UseFormSetValue<IPayroll>;
    getValues: UseFormGetValues<IPayroll>;
    reports: IReport[];
    generalInfos: IGeneralInfo[];
}

const PayrollEntriesForm = (props: Props) => {
    const { fields, append, remove } = useFieldArray({ control: props.control, name: 'payroll_entries' });

    const handleAddEntry = () => {
        append(new PayrollEntry());
    }

    const handleRemoveEntry = (index: number) => {
        remove(index);
    }

    const handleEmployeeChange = (value: IEmployee | null, index: number) => {
        props.setValue(`payroll_entries.${index}.employee`, value);
        props.setValue(`payroll_entries.${index}.employee_id`, value?.id || 0);
    }

    return (
        <Grid container item spacing={3}>
            <Grid container item spacing={1} direction={'column'}>
                <Grid container item justifyContent={"space-between"}>
                    <Grid item>
                        <Typography variant={"h6"}>
                            Entries
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            onClick={handleAddEntry}
                            startIcon={<AddIcon />}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
            </Grid>
            {fields.map((field, fieldIndex) => (
                <Grid item xs={12} key={field.id}>
                    <BorderBlock
                        title={(
                            <FpcEmployeeTitle
                                control={props.control}
                                baseName={`payroll_entries.${fieldIndex}`}
                            />
                        )}
                        onRemoveClick={() => handleRemoveEntry(fieldIndex)}
                    >
                        <Grid container spacing={3} alignItems={"center"}>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    control={props.control}
                                    name={`payroll_entries.${fieldIndex}.employee`}
                                    render={({ field: { value } }) => (
                                        <Autocomplete
                                            options={props.employees}
                                            value={value}
                                            isOptionEqualToValue={(op, v) => op.id === v.id}
                                            getOptionLabel={op => `${formatFpcId(op.fpc_id)} ${op.name} ${op.last_name}`}
                                            onChange={(_, v) => handleEmployeeChange(v, fieldIndex)}
                                            renderInput={params =>
                                                <TextField {...params} label={"Employee"} />
                                            }
                                            fullWidth />
                                    )}
                                />
                            </Grid>
                            <PayrollEntrySalaryForm
                                control={props.control}
                                fieldIndex={fieldIndex}
                                setValue={props.setValue}
                                generalInfos={props.generalInfos}
                            />
                            <PayrollEntryDaysForm
                                control={props.control}
                                entryIndex={fieldIndex}
                                reports={props.reports}
                                setValue={props.setValue}
                                generalInfos={props.generalInfos}
                            />
                        </Grid>
                    </BorderBlock>
                </Grid>
            ))}
        </Grid>
    )
}

export default PayrollEntriesForm;
