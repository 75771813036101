import { IGeneralInfo } from "./generalInfo";
import { IEmployee } from "./employee";

export interface IPayrollEntryDay {
    id: number;
    entry_date: Date;
    total_amount: number;
    is_custom: boolean;
    additional_value: number;
    _destroy: boolean;
}

export class PayrollEntryDay implements IPayrollEntryDay {
    id: number = 0;
    entry_date: Date = new Date();
    total_amount: number = 0;
    is_custom: boolean = false;
    additional_value: number = 0;
    _destroy: boolean = false;
}

export interface IPayrollEntry {
    id: number;
    employee_id: number;
    employee: IEmployee | null;
    other_deductions: number;
    total_deductions: number;
    payroll_entry_days: IPayrollEntryDay[];
    _destroy: boolean;
}

export class PayrollEntry implements IPayrollEntry {
    id: number = 0;
    employee_id: number = 0;
    employee: IEmployee | null = null;
    other_deductions: number = 0;
    total_deductions: number = 0;
    payroll_entry_days: IPayrollEntryDay[] = [];
    _destroy: boolean = false;

    constructor(init?: IPayrollEntry) {
        Object.assign(this, init);
    }
}

export interface IPayroll {
    id: number;
    payroll_name: string;
    payroll_date: Date;
    updated_at?: Date;
    general_info_id: number;
    general_info: IGeneralInfo | null;

    payroll_entries: IPayrollEntry[];
}

export class Payroll implements IPayroll {
    id: number = 0;
    payroll_name: string = "Nómina Nueva";
    payroll_date: Date = new Date();
    updated_at?: Date;
    general_info_id: number = 0;
    general_info: IGeneralInfo | null = null;

    payroll_entries: IPayrollEntry[] = [];
}